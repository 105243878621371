import React, { useState } from 'react';
import axios from 'axios';
import './Modal.scss';

const AddTeamModal = ({ closeModal, updateTeamList }) => {
  const [teamName, setTeamName] = useState('');
  const [teamTag, setTeamTag] = useState('');
  const [teamLogo, setTeamLogo] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newTeam = {
        name: teamName,
        tag: teamTag,
        logo: teamLogo,  // Champ non obligatoire
      };
      await axios.post('https://api.supra-event.fr/api/tournament/teams', newTeam);
      updateTeamList(); // Mettre à jour la liste des équipes
      closeModal(); // Fermer la modal après l'ajout
    } catch (error) {
      console.error('Erreur lors de l\'ajout de l\'équipe:', error);
    }
  };

  return (
    <div className="modal-addteams">
      <div className="modal-content-addteams">
        <h2>Ajouter une équipe</h2>
        <form onSubmit={handleSubmit}>
          <input
            placeholder="Nom de l'équipe"
            type="text"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            required
          />
          <input
            placeholder="Tag de l'équipe"
            type="text"
            value={teamTag}
            onChange={(e) => setTeamTag(e.target.value)}
            required
          />
          <input
            placeholder="URL du logo"
            type="text"
            value={teamLogo}
            onChange={(e) => setTeamLogo(e.target.value)}
          />
          <button className="addteam" type="submit">Ajouter l'équipe</button>
          <button className="closeteam" type="button" onClick={closeModal}>Fermer</button>
        </form>
      </div>
    </div>
  );
};

export default AddTeamModal;
