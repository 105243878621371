import React, { useState, useEffect } from 'react';
import './Modal.scss';

const EditPartnerModal = ({ partner, closeModal, editPartner }) => {
  const [updatedPartner, setUpdatedPartner] = useState(partner);

  useEffect(() => {
    setUpdatedPartner(partner);
  }, [partner]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Assure-toi que updatedPartner contient les données mises à jour
      console.log('Données à envoyer pour l\'édition :', updatedPartner);
      await editPartner(updatedPartner); // Appel de la fonction pour éditer le partenaire
      closeModal(); // Ferme le modal après l'édition
    } catch (error) {
      console.error('Erreur lors de l\'édition du partenaire:', error);
    }
  };

  return (
    <div className="modal-editpartners">
      <div className="modal-content-editpartners">
        <h2>Éditer le partenaire</h2>
        <form onSubmit={handleSubmit}>
          <label>Titre</label>
          <input
            type="text"
            value={updatedPartner.title || ''}  // Utilise 'title' au lieu de 'name'
            onChange={(e) => setUpdatedPartner({ ...updatedPartner, title: e.target.value })}
          />

          <label>Description</label>
          <textarea
            type="text"
            value={updatedPartner.description || ''}
            onChange={(e) => setUpdatedPartner({ ...updatedPartner, description: e.target.value })}
          />

          <label>URL de l'image</label>
          <input
            type="text"
            value={updatedPartner.image_url || ''}  // Utilise 'image_url' au lieu de 'imageUrl'
            onChange={(e) => setUpdatedPartner({ ...updatedPartner, image_url: e.target.value })}
          />

          <button type="submit" className="save-editpartner">Enregistrer</button>
          <button type="button" onClick={closeModal} className="close-editpartner">Annuler</button>
        </form>
      </div>
    </div>
  );
};

export default EditPartnerModal;
