import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../../../../components/Navbar/Navbar';
import AddTeamModal from './Modals/AddTeamModal';
import EditTeamModal from './Modals/EditTeamModal';
import DeleteTeamModal from './Modals/DeleteTeamModal';
import './Teams.scss'; 
import Toolbar from '../../../../components/Toolbar/Toolbar';

const Teams = () => {
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [showAddTeamModal, setShowAddTeamModal] = useState(false);
  const [showEditTeamModal, setShowEditTeamModal] = useState(null);
  const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchTeams = async () => {
    try {
      const response = await axios.get('https://api.supra-event.fr/api/tournament/teams');
      setTeams(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des équipes:', error);
    }
  };

  const fetchPlayers = async () => {
    try {
      const response = await axios.get('https://api.supra-event.fr/api/tournament/players');
      setPlayers(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des joueurs:', error);
    }
  };

  useEffect(() => {
    fetchTeams();
    fetchPlayers();
  }, []);

  const openAddTeamModal = () => setShowAddTeamModal(true);
  const openEditTeamModal = (team) => setShowEditTeamModal(team);
  const openDeleteTeamModal = (teamId) => setShowDeleteTeamModal(teamId);
  const closeModals = () => {
    setShowAddTeamModal(false);
    setShowEditTeamModal(null);
    setShowDeleteTeamModal(null);
  };

  const updateTeamList = async () => {
    await fetchTeams();
    closeModals();
  };

  const removeTeamFromList = (teamId) => {
    setTeams((prevTeams) => prevTeams.filter((team) => team._id !== teamId));
    closeModals();
  };

  const handleSearch = (query) => setSearchQuery(query);
  const filteredTeams = teams.filter((team) =>
    team.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <section className="section-teams">
      <Navbar />
      <Toolbar onOpenModal={openAddTeamModal} onSearch={handleSearch} />
      <div className='teams-content'>
        <div className="teams-grid">
          {filteredTeams.map((team) => (
            <div className="team-card" key={team._id}>
              <img src={team.logo} alt={team.name} className="team-logo" />
              <h3>{team.name}</h3>
              <div className='team-players'>
                <p><strong>Joueurs:</strong></p>
                <ul>
                  {players
                    .filter((player) => player.team === team.name)
                    .map((player) => (
                      <li 
                        key={player._id} 
                        className={player._id === team.captain?.id ? 'captain' : ''}
                      >
                        {player.name}{player._id === team.captain?.id ? ' (Capitaine)' : ''}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="team-actions">
                <button className="edit-btn" onClick={() => openEditTeamModal(team)}>Éditer</button>
                <button className="delete-btn" onClick={() => openDeleteTeamModal(team._id)}>Supprimer</button>
              </div>
            </div>
          ))}
        </div>
  
        {/* Modals */}
        {showAddTeamModal && <AddTeamModal closeModal={closeModals} updateTeamList={updateTeamList} />}
        {showEditTeamModal && (
          <EditTeamModal 
            team={showEditTeamModal} 
            closeModal={closeModals} 
            allPlayers={players} 
            updateTeams={updateTeamList}
          />
        )}
        {showDeleteTeamModal && <DeleteTeamModal teamId={showDeleteTeamModal} closeModal={closeModals} removeTeamFromList={removeTeamFromList} />}
      </div>
    </section>
  );
  
  
};

export default Teams;
