import React, { useState, useEffect } from 'react';
import Navbar from '../../../../components/Navbar/Navbar';
import AddMapModal from './Modals/AddMapsModal';
import EditMapModal from './Modals/EditMapsModal';
import DeleteMapModal from './Modals/DeleteMapsModal';
import './Maps.scss';
import Toolbar from '../../../../components/Toolbar/Toolbar';

const Maps = () => {
  const [showAddMapModal, setShowAddMapModal] = useState(false);
  const [showEditMapModal, setShowEditMapModal] = useState(null);
  const [showDeleteMapModal, setShowDeleteMapModal] = useState(null);
  const [maps, setMaps] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Fonction pour récupérer les cartes depuis l'API
  const fetchMaps = async () => {
    try {
      const response = await fetch('https://api.supra-event.fr/api/tournament/maps');
      const data = await response.json();
      setMaps(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des maps', error);
    }
  };

  // Effectuer la récupération des cartes au chargement du composant
  useEffect(() => {
    fetchMaps();
  }, []);

  const openAddMapModal = () => setShowAddMapModal(true);
  const openEditMapModal = (map) => setShowEditMapModal(map);
  const openDeleteMapModal = (mapId) => setShowDeleteMapModal(mapId);
  const closeModals = () => {
    setShowAddMapModal(false);
    setShowEditMapModal(null);
    setShowDeleteMapModal(null);
  };

  // Met à jour la liste des cartes après l'ajout d'une nouvelle carte
  const updateMapList = async () => {
    await fetchMaps();
    closeModals();
  };

  const updateMap = (updatedMap) => {
    setMaps((prevMaps) =>
      prevMaps.map((map) =>
        map._id === updatedMap._id ? updatedMap : map
      )
    );
  };

  const removeMapsFromList = (mapId) => {
    setMaps((prevMaps) =>
      prevMaps.filter((map) => map._id !== mapId)
    );
    closeModals();
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  // Filtrer les cartes selon la requête de recherche
  const filteredMaps = maps.filter(map =>
    map.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <section className="section-maps">
      <Navbar />
      <Toolbar onOpenModal={openAddMapModal} onSearch={handleSearch} />
      <div className='maps-content'>

        <div className="maps-grid">
          {filteredMaps.map((map) => (
            <div className="map-card" key={map._id}>
              <h3>{map.name}</h3>
              <div className="map-actions">
                <button className='edit-btn' onClick={() => openEditMapModal(map)}>Éditer</button>
                <button className="delete-btn" onClick={() => openDeleteMapModal(map._id)}>Supprimer</button>
              </div>
            </div>
          ))}
        </div>

        {/* Modals */}
        {showAddMapModal && <AddMapModal closeModal={closeModals} updateMapList={updateMapList} />}
        {showEditMapModal && (
          <EditMapModal map={showEditMapModal} closeModal={closeModals} updateMap={updateMap} />
        )}
        {showDeleteMapModal && (
          <DeleteMapModal mapId={showDeleteMapModal} closeModal={closeModals} removeMapsFromList={removeMapsFromList} />
        )}
      </div>
    </section>
  );
};

export default Maps;
