import React, { useState, useEffect } from 'react';
import './Modal.scss';

const EditStaffModal = ({ staff, closeModal, editStaff }) => {
  const [updatedStaff, setUpdatedStaff] = useState(staff);

  // Met à jour l'état quand la prop staff change
  useEffect(() => {
    setUpdatedStaff(staff);
  }, [staff]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Appelle la fonction pour éditer le staff
      await editStaff(updatedStaff); // Utilise la fonction editStaff passée en prop
      closeModal(); // Ferme le modal après l'édition
    } catch (error) {
      console.error('Erreur lors de l\'édition du membre du staff:', error);
    }
  };

  return (
    <div className="modal-editstaffs">
      <div className="modal-content-editstaffs">
        <h2>Éditer le membre du staff</h2>
        <form onSubmit={handleSubmit}>
          <label>Nom</label>
          <input
            type="text"
            value={updatedStaff.name}
            onChange={(e) => setUpdatedStaff({ ...updatedStaff, name: e.target.value })}
          />

          <label>Description</label>
          <textarea
            type="text"
            value={updatedStaff.description}
            onChange={(e) => setUpdatedStaff({ ...updatedStaff, description: e.target.value })}
          />

          <label>URL de l'image</label>
          <input
            type="text"
            value={updatedStaff.imageUrl}
            onChange={(e) => setUpdatedStaff({ ...updatedStaff, imageUrl: e.target.value })}
          />

          <button type="submit" className="save-editstaff">Enregistrer</button>
          <button type="button" onClick={closeModal} className="close-editstaff">Annuler</button>
        </form>
      </div>
    </div>
  );
};

export default EditStaffModal;
