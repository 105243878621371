import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Modal.scss';

const EditMatchModal = ({ match, closeModal, updateMatches, teams, maps }) => {
  const [teamLeft, setTeamLeft] = useState('');
  const [teamRight, setTeamRight] = useState('');
  const [mapName, setMapName] = useState('');
  const [matchType, setMatchType] = useState('');

  useEffect(() => {
    const fetchMatchData = async () => {
      if (match && match._id) {
        try {
          const response = await axios.get(`https://api.supra-event.fr/api/tournament/matches/${match._id}`);
          const matchData = response.data;
          setTeamLeft(matchData.teamLeft);
          setTeamRight(matchData.teamRight);
          setMapName(matchData.mapName);
          setMatchType(matchData.matchType);
        } catch (error) {
          console.error('Erreur lors de la récupération des données du match:', error);
        }
      }
    };

    fetchMatchData();
  }, [match]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!match || !match._id) {
      console.error('Match ID is not defined');
      return;
    }
    try {
      await axios.put(`https://api.supra-event.fr/api/tournament/matches/${match._id}`, {
        teamLeft,
        teamRight,
        mapName,
        matchType,
        scoreLeft: 0,  // Ajoutez les champs appropriés si nécessaire
        scoreRight: 0, // Ajoutez les champs appropriés si nécessaire
        inProgress: false  // Ajoutez les champs appropriés si nécessaire
      });
      closeModal();
      updateMatches(); // Mettre à jour la liste des matchs
    } catch (error) {
      console.error('Erreur lors de la mise à jour du match:', error);
    }
  };

  return (
    <div className="modal-editmatches">
      <div className="modal-content-editmatches">
        <h2>Éditer un Match</h2>
        <form onSubmit={handleSubmit}>
          <label>Team Left</label>
          <select value={teamLeft} onChange={(e) => setTeamLeft(e.target.value)} required>
            <option value="">Select Team</option>
            {teams.length > 0 && teams.map(team => (
              <option key={team._id} value={team.name}>{team.tag}</option>
            ))}
          </select>

          <label>Team Right</label>
          <select value={teamRight} onChange={(e) => setTeamRight(e.target.value)} required>
            <option value="">Select Team</option>
            {teams.length > 0 && teams.map(team => (
              <option key={team._id} value={team.name}>{team.tag}</option>
            ))}
          </select>

          <label>Map Name</label>
          <select value={mapName} onChange={(e) => setMapName(e.target.value)} required>
            <option value="">Select Map</option>
            {maps.length > 0 && maps.map(map => (
              <option key={map._id} value={map.name}>{map.name}</option>
            ))}
          </select>

          <label>Match Type</label>
          <select value={matchType} onChange={(e) => setMatchType(e.target.value)} required>
            <option value="Qualif">Qualif</option>
            <option value="Eliminatoire">Eliminatoire</option>
            <option value="Finales">Finales</option>
          </select>

          <button className='save-editmatches' type="submit">Sauvegarder</button>
          <button className='close-editmatches' type="button" onClick={closeModal}>Fermer</button>
        </form>
      </div>
    </div>
  );
};

export default EditMatchModal;
