import React, { useState, useEffect } from "react";
import './Sanction.scss';
import Navbar from "../../../../components/Navbar/Navbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitch, faDiscord, faTiktok } from '@fortawesome/free-brands-svg-icons';
import AddSanctionModal from './Modals/AddSanctionModal';
import DeleteSanctionModal from './Modals/DeleteSanctionModal';
import EditSanctionModal from './Modals/EditSanctionModal';
import Toolbar from "../../../../components/Toolbar/Toolbar";

const Sanction = () => {
    const [sanctions, setSanctions] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [sanctionToDelete, setSanctionToDelete] = useState(null);
    const [sanctionToEdit, setSanctionToEdit] = useState(null);
    const [searchTerm, setSearchTerm] = useState(''); // État pour le terme de recherche

    const fetchSanctions = async () => {
        try {
            const response = await fetch("https://api.supra-event.fr/api/staffboard/sanctions");
            const data = await response.json();
            setSanctions(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des sanctions:", error);
        }
    };

    useEffect(() => {
        fetchSanctions();
    }, []);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    
    const openModal = (type, sanction) => {
        if (type === 'delete') {
            setSanctionToDelete(sanction);
            setDeleteModalOpen(true);
        } else if (type === 'edit') {
            setSanctionToEdit(sanction);
            setEditModalOpen(true);
        }
    };
    
    const closeModal = (type) => () => {
        if (type === 'delete') {
            setDeleteModalOpen(false);
            setSanctionToDelete(null);
        } else if (type === 'edit') {
            setEditModalOpen(false);
            setSanctionToEdit(null);
        }
    };

    const handleDeleteSanction = (sanctionId) => {
        setSanctions(prevSanctions => prevSanctions.filter(sanction => sanction._id !== sanctionId));
        closeModal("delete")();
    };

    const handleUpdateSanction = (updatedSanction) => {
        setSanctions(prevSanctions =>
            prevSanctions.map(sanction =>
                sanction._id === updatedSanction._id ? updatedSanction : sanction
            )
        );
        closeModal("edit")();
    };

    const getNetworkIcon = (network) => {
        const icons = {
            "Twitch": faTwitch,
            "Discord": faDiscord,
            "TikTok": faTiktok
        };
        return <FontAwesomeIcon className={`icon-${network.toLowerCase()}`} icon={icons[network] || null} />;
    };

    // Filtrer les sanctions en fonction du terme de recherche
    const filteredSanctions = sanctions.filter(sanction =>
        sanction.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <section className="section-sanction">
            <Navbar />
            <Toolbar onOpenModal={toggleModal} onSearch={setSearchTerm} /> {/* Passer setSearchTerm pour mettre à jour le terme de recherche */}

            {/* Affichage des sanctions */}
            <div className="sanction-container">
                {filteredSanctions.length > 0 ? (
                    filteredSanctions.map((sanction, index) => (
                        <div className="sanction-card" key={index}>
                            <h2>{sanction.username}</h2>
                            <div className="network-icon">
                                {getNetworkIcon(sanction.network)}
                            </div>
                            <div className="sanction-rapport">
                                <p><strong>Modérateur :</strong> {sanction.moderator}</p>
                                <p><strong>Type de sanction :</strong> {sanction.type}</p>
                                <p className="last-rapport"><strong>Raison :</strong> {sanction.reason}</p>
                            </div>
                            <button className="btn-edit-sanction" onClick={() => openModal('edit', sanction)}>
                                Éditer
                            </button>
                            <button className="btn-delete-sanction" onClick={() => openModal('delete', sanction)}>
                                Supprimer
                            </button>
                        </div>
                    ))
                ) : (
                    <p>Aucune sanction trouvée.</p>
                )}
            </div>

            {modalOpen && <AddSanctionModal closeModal={toggleModal} addSanction={fetchSanctions} />}
            {deleteModalOpen && sanctionToDelete && (
                <DeleteSanctionModal closeModal={closeModal('delete')} sanction={sanctionToDelete} deleteSanction={handleDeleteSanction} />
            )}
            {editModalOpen && sanctionToEdit && (
                <EditSanctionModal closeModal={closeModal('edit')} sanction={sanctionToEdit} updateSanction={handleUpdateSanction} />
            )}
        </section>
    );
};

export default Sanction;
