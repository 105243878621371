import React, { useState, useEffect } from 'react';
import './Modal.scss'; // Assure-toi d'importer les styles pour le modal

const EditStudioModal = ({ studio, closeModal, editStudio }) => {
  const [updatedStudio, setUpdatedStudio] = useState(studio);

  useEffect(() => {
    setUpdatedStudio(studio);
  }, [studio]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await editStudio(updatedStudio); // Utilise la fonction editStudio passée en prop
      closeModal(); // Ferme le modal après l'édition
    } catch (error) {
      console.error('Erreur lors de l\'édition du studio:', error);
    }
  };

  return (
    <div className="modal-editstudios">
      <div className="modal-content-editstudios">
        <h2>Éditer le studio</h2>
        <form onSubmit={handleSubmit}>
          <label>Titre</label>
          <input
            type="text"
            value={updatedStudio.title || ''}
            onChange={(e) => setUpdatedStudio({ ...updatedStudio, title: e.target.value })}
          />

          <label>Description</label>
          <textarea
            value={updatedStudio.description || ''}
            onChange={(e) => setUpdatedStudio({ ...updatedStudio, description: e.target.value })}
          />

          <label>URL de l'image/vidéo</label>
          <input
            type="text"
            value={updatedStudio.imageUrl || ''} // Utilise imageUrl pour la mise à jour de l'URL
            onChange={(e) => setUpdatedStudio({ ...updatedStudio, imageUrl: e.target.value })}
          />

          <button type="submit" className="save-editstudio">Enregistrer</button>
          <button type="button" onClick={closeModal} className="close-editstudio">Annuler</button>
        </form>
      </div>
    </div>
  );
};

export default EditStudioModal;
