import React, { useState } from 'react';
import axios from 'axios';
import './Modal.scss';

const EditMapModal = ({ map, closeModal, updateMap }) => {
  const [mapName, setMapName] = useState(map.name);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedMap = {
        name: mapName // Assure-toi que la clé est `name` si c'est ce que ton modèle attend
      };
      const response = await axios.put(`https://api.supra-event.fr/api/tournament/maps/${map._id}`, updatedMap);
      updateMap(response.data); // Met à jour la liste des cartes après l'édition
      closeModal();
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la carte:', error);
    }
  };

  return (
    <div className="modal-editmaps">
      <div className="modal-content-editmaps">
        <h2>Éditer la Map</h2>
        <form onSubmit={handleSubmit}>
          <label>Nom de la Map</label>
          <input 
            type="text" 
            value={mapName} 
            onChange={(e) => setMapName(e.target.value)} 
            required 
          />
          <button className='save-editmap' type="submit">Sauvegarder</button>
          <button className='close-editmap' type="button" onClick={closeModal}>Fermer</button>
        </form>
      </div>
    </div>
  );
};

export default EditMapModal;
