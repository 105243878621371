import React, { useState, useEffect } from 'react';
import Navbar from '../../../../components/Navbar/Navbar';
import AddPlayerModal from './Modals/AddPlayerModal';
import EditPlayerModal from './Modals/EditPlayerModal';
import DeletePlayerModal from './Modals/DeletePlayerModal';
import Toolbar from '../../../../components/Toolbar/Toolbar';
import './Players.scss'; 

const Players = () => {
  const [players, setPlayers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [showAddPlayerModal, setShowAddPlayerModal] = useState(false);
  const [showEditPlayerModal, setShowEditPlayerModal] = useState(null);
  const [showDeletePlayerModal, setShowDeletePlayerModal] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Récupérer les joueurs depuis l'API
  useEffect(() => {
    fetch('https://api.supra-event.fr/api/tournament/players')
      .then((response) => response.json())
      .then((data) => setPlayers(data))
      .catch((error) => console.error('Erreur lors de la récupération des joueurs:', error));
  }, []);

  // Récupérer les équipes depuis l'API
  useEffect(() => {
    fetch('https://api.supra-event.fr/api/tournament/teams')
      .then((response) => response.json())
      .then((data) => setTeams(data))
      .catch((error) => console.error('Erreur lors de la récupération des équipes:', error));
  }, []);

  const openAddPlayerModal = () => {
    setShowAddPlayerModal(true);
  };

  const openEditPlayerModal = (player) => {
    setShowEditPlayerModal(player);
  };

  const openDeletePlayerModal = (playerId) => {
    setShowDeletePlayerModal(playerId);
  };

  const closeModals = () => {
    setShowAddPlayerModal(false);
    setShowEditPlayerModal(null);
    setShowDeletePlayerModal(null);
  };

  const updatePlayerList = (newPlayer) => {
    setPlayers((prevPlayers) => [...prevPlayers, newPlayer]);
    closeModals();
  };
  
  const updatePlayer = (updatedPlayer) => {
    setPlayers((prevPlayers) =>
      prevPlayers.map((player) =>
        player._id === updatedPlayer._id ? updatedPlayer : player
      )
    );
    closeModals();
  };

  const removePlayerFromList = (playerId) => {
    setPlayers((prevPlayers) =>
      prevPlayers.filter((player) => player._id !== playerId)
    );
    closeModals();
  };

  const getTeamTag = (teamName) => {
    const team = teams.find((team) => team.name === teamName);
    return team ? team.tag : 'No team'; // Retourne le tag ou 'No team' si non trouvé
  };

  // Filtrer les joueurs en fonction du terme de recherche
  const filteredPlayers = players.filter(player => 
    player.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Fonction pour gérer la recherche depuis Toolbar
  const handleSearch = (query) => {
    setSearchTerm(query);
  };

  return (
    <section className="section-players">
      <Navbar />
      <Toolbar onOpenModal={openAddPlayerModal} onSearch={handleSearch} />
      <div className='players-content'>
        <div className="players-grid">
          {/* Utiliser le tableau filtré pour afficher les joueurs */}
          {filteredPlayers.map((player) => (
            <div className="player-card" key={player._id}>
              <img src={player.profileImage} alt={player.name} className="player-image" />
              <h3>{player.name}</h3>
              <div className='player-info'>
                <p><strong>Équipe:</strong> {getTeamTag(player.team)}</p>
                <p><strong>Kills:</strong> {player.kills}</p>
                <p><strong>Deaths:</strong> {player.deaths}</p>
              </div>
              <div className="player-actions">
                <button className="edit-btn" onClick={() => openEditPlayerModal(player)}>Éditer</button>
                <button className="delete-btn" onClick={() => openDeletePlayerModal(player._id)}>Supprimer</button>
              </div>
            </div>
          ))}
        </div>

        {/* Modals */}
        {showAddPlayerModal && (<AddPlayerModal closeModal={closeModals} updatePlayerList={updatePlayerList}/>)}
        {showEditPlayerModal && (<EditPlayerModal player={showEditPlayerModal} closeModal={closeModals} updatePlayer={updatePlayer}/>)}
        {showDeletePlayerModal && <DeletePlayerModal playerId={showDeletePlayerModal} closeModal={closeModals} removePlayerFromList={removePlayerFromList} />}
      </div>
    </section>
  );
};

export default Players;
