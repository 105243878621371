import React from 'react';
import axios from 'axios';
import './Modal.scss'; // Assure-toi que ce fichier contient les styles nécessaires

const DeleteUserModal = ({ userId, closeModal, removeUserFromList }) => {
  const handleDelete = async () => {
    try {
      await axios.delete(`https://api.supra-event.fr/api/users/${userId}`);
      removeUserFromList(userId); // Met à jour la liste des utilisateurs après la suppression
      closeModal(); // Ferme le modal après la suppression
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'utilisateur:', error.message);
    }
  };

  return (
    <div className="modal-deleteusers">
      <div className="modal-content-deleteusers">
        <h2>Supprimer l'Utilisateur</h2>
        <p>Êtes-vous sûr de vouloir supprimer cet utilisateur ?</p>
        <div className='button-overlay'>
          <button className="delete-deleteuser" onClick={handleDelete}>Supprimer</button>
          <button className='close-deleteuser' onClick={closeModal}>Fermer</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserModal;
