import React, { useEffect, useState } from "react";
import Navbar from "../../../../components/Navbar/Navbar";
import Toolbar from "../../../../components/Toolbar/Toolbar";
import './PixBot.scss';

import AddPixBotModal from "./Modals/AddPixBotModal";
import EditPixBotModal from "./Modals/EditPixBotModal";
import DeletePixBotModal from "./Modals/DeletePixBotModal";

const PixBot = () => {
    const [showAddPixBotModal, setShowAddPixBotModal] = useState(false);
    const [showEditPixBotModal, setShowEditPixBotModal] = useState(null);
    const [showDeletePixBotModal, setShowDeletePixBotModal] = useState(null);
    const [pixbotList, setPixBotList] = useState([]); 
    const [searchTerm, setSearchTerm] = useState('');

    // Fonction pour récupérer les données depuis l'API
    const fetchPixBot = async () => {
        try {
            const response = await fetch('https://api.supra-event.fr/api/staffboard/pixbot');
            if (!response.ok) {  // Vérifie si la réponse est valide
                throw new Error(`Erreur: ${response.status}`);
            }
            const data = await response.json();
            setPixBotList(data);  // Mettre à jour la liste avec les données de l'API
        } catch (error) {
            console.error('Erreur lors de la récupération des commandes pixbot', error);
        }
    };
    

    // Effect qui s'exécute lors du montage du composant pour récupérer les données
    useEffect(() => {
        fetchPixBot();
    }, []);

    // Ouvrir/fermer les modals
    const openAddPixBotModal = () => setShowAddPixBotModal(true);
    const openEditPixBotModal = (pixbot) => setShowEditPixBotModal(pixbot);
    const openDeletePixBotModal = (pixbotId) => setShowDeletePixBotModal(pixbotId)
    const closeModals = () => {
        setShowAddPixBotModal(false);
        setShowEditPixBotModal(null);
        setShowDeletePixBotModal(null);
    };
    

    const updatePixBotList = async () => {
        await fetchPixBot();  // Ensuite, mets à jour la liste
        closeModals();  // Ferme les modals immédiatement
    };
    

    const removePixBotFromList = (pixbotId) => {
        setPixBotList((prevPixBotList) => 
            prevPixBotList.filter((pixbot) => pixbot._id !== pixbotId)
        );
        closeModals();
    };

    const filteredPixBotList = pixbotList.filter(pixbot =>
        pixbot.trigger.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <section className="section-pixbot">
            <Navbar />
            <Toolbar onOpenModal={openAddPixBotModal} onSearch={setSearchTerm} /> 
            <div className='pixbot-content'>
                <div className="pixbot-grid">
                    {filteredPixBotList.length > 0 ? (
                        filteredPixBotList.map((pixbot) => (
                            <div className="pixbot-card" key={pixbot._id}>
                                <h2>{pixbot.trigger}</h2>
                                <p className="trigger-pixbot">{pixbot.alias || "Aucun alias"}</p>
                                <p>{pixbot.message}</p>
                                
                                <div className="pixbot-actions">
                                    <button className='edit-btn' onClick={() => openEditPixBotModal(pixbot)}>Éditer</button>
                                    <button className="delete-btn" onClick={() => openDeletePixBotModal(pixbot._id)}>Supprimer</button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Aucun PixBot trouvé.</p>
                    )}
                </div>

                {showAddPixBotModal && <AddPixBotModal closeModal={closeModals} updatePixBotList={updatePixBotList} />}
                {showEditPixBotModal && (<EditPixBotModal pixbot={showEditPixBotModal} closeModal={closeModals} updatePixBot={updatePixBotList}/>)}
                {showDeletePixBotModal && (<DeletePixBotModal pixbotId={showDeletePixBotModal} closeModal={closeModals} removePixBotFromList={removePixBotFromList}/>)}

            </div>
        </section>
    );
}

export default PixBot;
