import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Modal.scss';

const AddMatchModal = ({ closeModal, updateMatches }) => {
  const [teamLeft, setTeamLeft] = useState('');
  const [teamRight, setTeamRight] = useState('');
  const [mapName, setMapName] = useState('');
  const [matchType, setMatchType] = useState('Qualif');
  
  const [teams, setTeams] = useState([]);
  const [maps, setMaps] = useState([]);

  useEffect(() => {
    // Fetch teams
    const fetchTeams = async () => {
      try {
        const response = await axios.get('https://api.supra-event.fr/api/tournament/teams');
        setTeams(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des équipes:', error);
      }
    };

    // Fetch maps
    const fetchMaps = async () => {
      try {
        const response = await axios.get('https://api.supra-event.fr/api/tournament/maps');
        setMaps(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des cartes:', error);
      }
    };

    fetchTeams();
    fetchMaps();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Envoyer les données du match au serveur
      await axios.post('https://api.supra-event.fr/api/tournament/matches', {
        teamLeft,
        teamRight,
        mapName,
        matchType,
        scoreLeft: 0,
        scoreRight: 0,
        inProgress: false
      });
      // Fermer le modal et mettre à jour la liste des matchs
      closeModal();
      updateMatches(); // Mettre à jour la liste des matchs
    } catch (error) {
      console.error('Erreur lors de l\'ajout du match:', error);
    }
  };

  return (
    <div className="modal-addmatches">
      <div className="modal-content-addmatches">
        <h2>Ajouter un Match</h2>
        <form onSubmit={handleSubmit}>
          <label>Team Left</label>
          <select 
            value={teamLeft} 
            onChange={(e) => setTeamLeft(e.target.value)} 
            required
          >
            <option value="">Select Team</option>
            {teams.map(team => (
              <option key={team._id} value={team.name}>
                {team.tag}
              </option>
            ))}
          </select>

          <label>Team Right</label>
          <select 
            value={teamRight} 
            onChange={(e) => setTeamRight(e.target.value)} 
            required
          >
            <option value="">Select Team</option>
            {teams.map(team => (
              <option key={team._id} value={team.name}>
                {team.tag}
              </option>
            ))}
          </select>

          <label>Map Name</label>
          <select 
            value={mapName} 
            onChange={(e) => setMapName(e.target.value)} 
            required
          >
            <option value="">Select Map</option>
            {maps.map(map => (
              <option key={map._id} value={map.name}>
                {map.name}
              </option>
            ))}
          </select>

          <label>Match Type</label>
          <select 
            value={matchType} 
            onChange={(e) => setMatchType(e.target.value)} 
            required
          >
            <option value="Qualif">Qualif</option>
            <option value="Eliminatoire">Eliminatoire</option>
            <option value="Finales">Finales</option>
          </select>

          <button className='addmatches' type="submit">Ajouter</button>
          <button className='closematches' type="button" onClick={closeModal}>Fermer</button>
        </form>
      </div>
    </div>
  );
};

export default AddMatchModal;
