import React, { useState } from "react";
import './Modal.scss';

const AddLiveControlModal = ({ closeModal, AddLiveControl }) => {
    const [title, setTitle] = useState("");
    const [redirection, setRedirection] = useState("");
    const [description, setDescription] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        const livecontrolData = {
            title,
            redirection,
            description
        };

        try {
            const response = await fetch("https://api.supra-event.fr/api/staffboard/livecontrol", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"  
                },
                body: JSON.stringify(livecontrolData) 
            });

            if (response.ok) {
                AddLiveControl(); // Rafraîchit les données
                closeModal(); // Ferme le modal
            } else {
                console.error("Erreur lors de l'ajout du live control");
            }
        } catch (error) {
            console.error("Erreur lors de l'ajout du live control :", error);
        }
    };

    return (
        <div className="modal-addlivecontrols">
            <div className="modal-content-addlivecontrols">
                <h2>Ajouter un Live Control</h2>
                <form onSubmit={handleSubmit}>
                    <label>
                        Titre:
                        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </label>
                    <label>
                        Redirection:
                        <input type="text" value={redirection} onChange={(e) => setRedirection(e.target.value)} />
                    </label>
                    <label>
                        Description:
                        <textarea type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                    </label>
                    <div className="form-actions">
                        <button className="add-livecontrol" type="submit">Ajouter</button>
                        <button className="close-livecontrol" type="button" onClick={closeModal}>Annuler</button> {/* Correction ici */}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddLiveControlModal;
