import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// Crée le contexte d'authentification
export const AuthContext = createContext();

// Provider d'authentification
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    // Récupérer l'utilisateur du localStorage lors de l'initialisation
    const savedUser = localStorage.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [error, setError] = useState(null);

  // Fonction de connexion
  const login = async (username, password) => {
    try {
      const response = await axios.post('https://api.supra-event.fr/api/users/login', {
        username,
        password,
      });
  
      const foundUser = response.data;
  
      if (foundUser) {
        setUser(foundUser);
        setError(null);
        localStorage.setItem('user', JSON.stringify(foundUser));
        return foundUser; // Retourne l'utilisateur pour l'utiliser dans le composant
      } else {
        throw new Error('Nom d\'utilisateur ou mot de passe incorrect');
      }
    } catch (err) {
      // En cas d'erreur, vous pouvez également vérifier si l'erreur provient d'une réponse
      if (err.response) {
        console.error('Erreur de connexion :', err.response.data.message);
        setError(err.response.data.message);
      } else {
        console.error('Erreur de connexion :', err.message);
        setError(err.message);
      }
      return null; // Retourne null en cas d'échec de connexion
    }
  };
  

  // Fonction de déconnexion
  const logout = async () => {
    try {
      // Optionnel : Vous pouvez appeler une API pour déconnecter l'utilisateur, si nécessaire
      // await axios.post('http://217.182.204.98:5000/api/users/logout'); // Exemple si une telle API existe
      
      // Réinitialiser l'état utilisateur
      setUser(null);

      // Supprimer l'utilisateur du localStorage lors de la déconnexion
      localStorage.removeItem('user');
    } catch (err) {
      setError(err.message);
    }
  };

  // Effet pour mettre à jour le localStorage lorsque l'utilisateur change
  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, login, logout, error }}>
      {children}
    </AuthContext.Provider>
  );
};
