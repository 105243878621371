import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../../../../components/Navbar/Navbar';
import AddMatchModal from './Modals/AddMatchModal';
import EditMatchModal from './Modals/EditMatchModal';
import DeleteMatchModal from './Modals/DeleteMatchModal';
import './Matchs.scss';
import Toolbar from '../../../../components/Toolbar/Toolbar';

const Matchs = () => {
  const [showAddMatchModal, setShowAddMatchModal] = useState(false);
  const [showEditMatchModal, setShowEditMatchModal] = useState(null);
  const [showDeleteMatchModal, setShowDeleteMatchModal] = useState(null);
  const [matches, setMatches] = useState([]);
  const [teams, setTeams] = useState([]);
  const [maps, setMaps] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Fetch matches, teams, and maps
  const fetchData = async () => {
    try {
      const [matchesResponse, teamsResponse, mapsResponse] = await Promise.all([
        axios.get('https://api.supra-event.fr/api/tournament/matches'),
        axios.get('https://api.supra-event.fr/api/tournament/teams'),
        axios.get('https://api.supra-event.fr/api/tournament/maps')
      ]);
      setMatches(matchesResponse.data);
      setTeams(teamsResponse.data);
      setMaps(mapsResponse.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des données:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openAddMatchModal = () => setShowAddMatchModal(true);
  const openEditMatchModal = (match) => setShowEditMatchModal(match);
  const openDeleteMatchModal = (matchId) => setShowDeleteMatchModal(matchId);
  const closeModals = () => {
    setShowAddMatchModal(false);
    setShowEditMatchModal(null);
    setShowDeleteMatchModal(null);
  };

  const getTeamTagByName = (teamName) => {
    const team = teams.find(t => t.name === teamName);
    return team ? team.tag : '';
  };

  const getMapNameByName = (mapName) => {
    const map = maps.find(m => m.name === mapName);
    return map ? map.name : '';
  };

  const adjustScore = async (matchId, team, adjustment) => {
    const updatedMatches = matches.map(match =>
      match._id === matchId
        ? { ...match, [`score${team === 'left' ? 'Left' : 'Right'}`]: match[`score${team === 'left' ? 'Left' : 'Right'}`] + adjustment }
        : match
    );
    setMatches(updatedMatches);

    try {
      const matchToUpdate = updatedMatches.find(match => match._id === matchId);
      await axios.put(`https://api.supra-event.fr/api/tournament/matches/${matchId}`, matchToUpdate);
    } catch (error) {
      console.error('Erreur lors de l\'ajustement du score:', error);
    }
  };

  const toggleMatchStatus = async (matchId) => {
    const updatedMatches = matches.map(match => {
      if (match._id === matchId) {
        const newStatus = !match.inProgress;
        return { ...match, inProgress: newStatus };
      }
      return match;
    });
    setMatches(updatedMatches);

    try {
      const matchToUpdate = updatedMatches.find(match => match._id === matchId);
      await axios.put(`https://api.supra-event.fr/api/tournament/matches/${matchId}`, matchToUpdate);
    } catch (error) {
      console.error('Erreur lors du changement de statut du match:', error);
    }
  };

  const switchSides = async (matchId) => {
    const updatedMatches = matches.map(match => {
      if (match._id === matchId) {
        return {
          ...match,
          teamLeft: match.teamRight,
          teamRight: match.teamLeft,
          scoreLeft: match.scoreRight,
          scoreRight: match.scoreLeft
        };
      }
      return match;
    });
    setMatches(updatedMatches);

    try {
      const matchToUpdate = updatedMatches.find(match => match._id === matchId);
      await axios.put(`https://api.supra-event.fr/api/tournament/matches/${matchId}`, matchToUpdate);
    } catch (error) {
      console.error('Erreur lors du changement des côtés:', error);
    }
  };

  // Fonction de recherche
  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  // Filtrage des matches sans distinction entre teamLeft et teamRight
  const filteredMatches = matches.filter((match) =>
    match.teamLeft.toLowerCase().includes(searchQuery.toLowerCase()) ||
    match.teamRight.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <section className="section-matches">
      <Navbar />
      <Toolbar onOpenModal={openAddMatchModal} onSearch={handleSearch} />
      <div className='matches-content'>
        <div className="matches-grid">
          {filteredMatches.map((match) => {
            const leftTag = getTeamTagByName(match.teamLeft);
            const rightTag = getTeamTagByName(match.teamRight);
            const mapName = getMapNameByName(match.mapName);
            const isLeftHigher = match.scoreLeft > match.scoreRight;
            const isInProgress = match.inProgress;

            return (
              <div className={`match-card ${isInProgress ? 'in-progress' : ''}`} key={match._id}>
                <h3>{`${leftTag} vs ${rightTag}`}</h3>
                <p className='mapname'>{mapName}</p>
                <div className="scores">
                  <div className={`score ${isLeftHigher ? 'high' : 'low'}`}>
                    <button onClick={() => adjustScore(match._id, 'left', 1)}>+</button>
                    <button onClick={() => adjustScore(match._id, 'left', -1)}>-</button>
                    <p>{match.scoreLeft}</p>
                  </div>
                  <div className='vs-separator'>VS</div>
                  <div className={`score ${!isLeftHigher ? 'high' : 'low'}`}>
                    <p>{match.scoreRight}</p>
                    <button onClick={() => adjustScore(match._id, 'right', 1)}>+</button>
                    <button onClick={() => adjustScore(match._id, 'right', -1)}>-</button>
                  </div>
                </div>
                <div className="match-actions">
                  <button onClick={() => toggleMatchStatus(match._id)}>
                    {isInProgress ? 'Off Progress' : 'In Progress'}
                  </button>
                  <button onClick={() => switchSides(match._id)}>Switch Sides</button>
                  <button onClick={() => openEditMatchModal(match)}>Edit</button>
                  <button onClick={() => openDeleteMatchModal(match._id)}>Delete</button>
                </div>
              </div>
            );
          })}
        </div>

        {/* Modals */}
        {showAddMatchModal && <AddMatchModal closeModal={closeModals} teams={teams} maps={maps} updateMatches={fetchData} />}
        {showEditMatchModal && (
          <EditMatchModal
            match={showEditMatchModal}
            closeModal={closeModals}
            teams={teams}
            maps={maps}
            updateMatches={fetchData}
          />
        )}
        {showDeleteMatchModal && <DeleteMatchModal matchId={showDeleteMatchModal} closeModal={closeModals} updateMatches={fetchData} />}
      </div>
    </section>
  );
};

export default Matchs;
