import React, { useState } from 'react';
import axios from 'axios';
import './Modal.scss';

const AddNewsModal = ({ closeModal, updateNewsList }) => {
  const [newsText, setNewsText] = useState('');  // Correspond à "text" dans le modèle
  const [newsColor, setNewsColor] = useState('rgb(255, 0, 166)');  // Correspond à "color" dans le modèle

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newNews = { 
        text: newsText,  // Utilise "text" pour correspondre au modèle
        color: newsColor // Utilise "color" pour correspondre au modèle
      };
      await axios.post('https://api.supra-event.fr/api/staffboard/news', newNews);
      updateNewsList();  // Met à jour la liste des actualités après ajout
      closeModal();  // Ferme la fenêtre modale après l'ajout
    } catch (error) {
      console.error('Erreur lors de l\'ajout de l\'actualité:', error);
    }
  };

  return (
    <div className="modal-addnews">
      <div className="modal-content-addnews">
        <h2>Ajouter une Actualité</h2>
        <form onSubmit={handleSubmit}>
          
          <label>Contenu de l'actualité</label>
          <textarea 
            value={newsText} 
            onChange={(e) => setNewsText(e.target.value)} 
            required 
          />

          <label>Catégorie (Couleur de l'actualité)</label>
          <select 
            value={newsColor} 
            onChange={(e) => setNewsColor(e.target.value)} 
            required
          >
            <option value="rgb(255, 0, 166)">News Gaming</option>
            <option value="rgb(255, 0, 0)">Message Communauté</option>
            <option value="yellow">Annonce</option>
          </select>

          <button className='add-news' type="submit">Ajouter</button>
          <button className='close-news' type="button" onClick={closeModal}>Fermer</button>
        </form>
      </div>
    </div>
  );
};

export default AddNewsModal;
