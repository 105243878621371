import React from 'react';
import axios from 'axios'; // Assurez-vous d'avoir axios installé
import './Modal.scss';

const DeleteMatchModal = ({ matchId, closeModal, updateMatches }) => {
  const handleDelete = async () => {
    try {
      // Envoyer une requête DELETE au serveur pour supprimer le match
      await axios.delete(`https://api.supra-event.fr/api/tournament/matches/${matchId}`);
      // Mettre à jour la liste des matchs après la suppression
      updateMatches();
      // Fermer le modal
      closeModal();
    } catch (error) {
      console.error('Erreur lors de la suppression du match:', error);
      // Optionnel: ajouter une gestion des erreurs plus visible pour l'utilisateur
    }
  };

  return (
    <div className="modal-deletematches">
      <div className="modal-content-deletematches">
        <h2>Supprimer le match</h2>
        <p>Êtes-vous sûr de vouloir supprimer ce match ?</p>
        <div className='button-overlay'>
          <button className="delete-btn" onClick={handleDelete}>Supprimer</button>
          <button className='close-btn' onClick={closeModal}>Fermer</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteMatchModal;
