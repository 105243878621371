import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Modal.scss';

const EditPlayerModal = ({ player, closeModal, updatePlayer  }) => {
  const [name, setName] = useState(player.name);
  const [profileImage, setProfileImage] = useState(player.profileImage);
  const [team, setTeam] = useState(player.team);
  const [kills, setKills] = useState(player.kills);
  const [deaths, setDeaths] = useState(player.deaths);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get('https://api.supra-event.fr/api/tournament/teams');
        setTeams(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des équipes:', error);
      }
    };

    fetchTeams();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedPlayer = {
        name,
        profileImage,
        team,
        kills,
        deaths
      };
      const response = await axios.put(`https://api.supra-event.fr/api/tournament/players/${player._id}`, updatedPlayer);
      updatePlayer(response.data); // Passe l'élément mis à jour au parent
      closeModal();
    } catch (error) {
      console.error('Erreur lors de la mise à jour du joueur:', error);
    }
  };

  return (
    <div className="modal-editplayers">
      <div className="modal-content-editplayers">
        <h2>Éditer un Joueur</h2>
        <form onSubmit={handleSubmit}>
          <label>Pseudo</label>
          <input 
            type="text" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            required 
          />
          <label>Url Image</label>
          <input 
            type="text" 
            value={profileImage} 
            onChange={(e) => setProfileImage(e.target.value)} 
            required 
          />
          <label>Team</label>
          <select 
            value={team} 
            onChange={(e) => setTeam(e.target.value)} 
            required
          >
            {teams.map((team) => (
              <option key={team._id} value={team.name}>
                {team.tag}
              </option>
            ))}
          </select>
          <label>Kills</label>
          <input 
            type="number" 
            value={kills} 
            onChange={(e) => setKills(e.target.value)} 
            required 
          />
          <label>Deaths</label>
          <input 
            type="number" 
            value={deaths} 
            onChange={(e) => setDeaths(e.target.value)} 
            required 
          />
          <button className='save-editplayers' type="submit">Sauvegarder</button>
          <button className='close-editplayers' type="button" onClick={closeModal}>Fermer</button>
        </form>
      </div>
    </div>
  );
};

export default EditPlayerModal;
