import React from 'react';
import axios from 'axios';
import './Modal.scss';

const DeletePlayerModal = ({ playerId, closeModal, removePlayerFromList }) => {
  const handleDelete = async () => {
    try {
      await axios.delete(`https://api.supra-event.fr/api/tournament/players/${playerId}`);
      removePlayerFromList(playerId); // Appelle la fonction pour supprimer le joueur de la liste dans le parent
      closeModal();
    } catch (error) {
      console.error('Erreur lors de la suppression du joueur:', error);
    }
  };

  return (
    <div className="modal-deleteplayers">
      <div className="modal-content-deleteplayers">
        <h2>Supprimer le joueur</h2>
        <p>Êtes-vous sûr de vouloir supprimer ce joueur ?</p>
        <div className='button-overlay'>
          <button className="delete-btn" onClick={handleDelete}>Supprimer</button>
          <button className='close-btn' onClick={closeModal}>Fermer</button>
        </div>
      </div>
    </div>
  );
};

export default DeletePlayerModal;
