import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const PrivateRoute = ({ component: Component, authorizedRoles, userRole, ...rest }) => {
  if (!userRole) {
    // Si l'utilisateur n'est pas connecté, redirige vers la page de connexion
    return <Navigate to="/" />;
  }

  if (!authorizedRoles.includes(userRole)) {
    // Si l'utilisateur est connecté mais n'a pas le bon rôle, redirige vers la page d'accès refusé
    return <Navigate to="/unauthorized" />;
  }

  // Sinon, affiche le composant requis
  return <Component {...rest} />;
};

export default PrivateRoute;
