import React, { useState } from 'react';
import axios from 'axios';
import './Modal.scss';

const AddRessourcesModal = ({ closeModal, updateResourcesList }) => {
  // Liste des services prédéfinis
  const predefinedServices = ["Wizebot", "Twitch", "Lumia", "Sammi", "Autres"];

  const [selectedService, setSelectedService] = useState(predefinedServices[0]); // Sélection par défaut
  const [commands, setCommands] = useState([{ command: '', description: '' }]); // Liste des commandes

  // Gérer le changement d'une commande individuelle
  const handleCommandChange = (index, field, value) => {
    const newCommands = [...commands];
    newCommands[index][field] = value;
    setCommands(newCommands);
  };

  // Ajouter un champ de commande
  const addCommandField = () => {
    setCommands([...commands, { command: '', description: '' }]);
  };

  // Supprimer un champ de commande
  const removeCommandField = (index) => {
    const newCommands = commands.filter((_, i) => i !== index);
    setCommands(newCommands);
  };

  // Gérer la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newService = {
        service: selectedService,  // Le service sélectionné (par exemple "WizeBot", "Twitch")
        commands: commands
      };
      await axios.post('https://api.supra-event.fr/api/staffboard/ressources/add-commands', newService);  // Assure-toi que l'URL est correcte
      updateResourcesList();
      closeModal();
    } catch (error) {
      console.error('Erreur lors de l\'ajout des commandes:', error.response ? error.response.data : error);
    }
  };

  return (
    <div className="modal-addressources">
      <div className="modal-content-addressources">
        <h2>Ajouter une Commande</h2>
        <form onSubmit={handleSubmit}>

          <label>Choisir un Service</label>
          <select 
            value={selectedService} 
            onChange={(e) => setSelectedService(e.target.value)} 
            required
          >
            {predefinedServices.map((service, index) => (
              <option key={index} value={service}>
                {service}
              </option>
            ))}
          </select>

          {commands.map((command, index) => (
            <div key={index} className="command-fields">
              <label>Commande</label>
              <input 
                type="text" 
                value={command.command} 
                onChange={(e) => handleCommandChange(index, 'command', e.target.value)} 
                required 
                placeholder="Ex: !to, !ban, etc." 
              />

              <label>Description</label>
              <input 
                type="text" 
                value={command.description} 
                onChange={(e) => handleCommandChange(index, 'description', e.target.value)} 
                required 
                placeholder="Description de la commande"
              />

              {index > 0 && (
                <button type="button" onClick={() => removeCommandField(index)}>Supprimer Commande</button>
              )}
            </div>
          ))}

          <button className='add-commands' type="button" onClick={addCommandField}>Ajouter</button>
          <button className='close-commands' type="button" onClick={closeModal}>Fermer</button>
        </form>
      </div>
    </div>
  );
};

export default AddRessourcesModal;
