import React, { useState } from 'react';
import axios from 'axios';
import './Modal.scss'; // Assure-toi d'importer les styles

const AddCardModal = ({ closeModal, updatePartners }) => {
  const [newCard, setNewCard] = useState({
    title: '',
    description: '',
    rarity: '',
    image_url: ''
  });

  const apiUrl = 'https://api.supra-event.fr/api/pixionautes/cardgames';

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Envoie des données au serveur
      const response = await axios.post(apiUrl, newCard);
      console.log('Carte ajoutée avec succès:', response.data);

      // Mise à jour des cartes avec la réponse du serveur
      if (response.data && typeof updatePartners === 'function') {
        updatePartners(prevPartners => [...prevPartners, response.data]);
      } else {
        console.error('updatePartners n\'est pas une fonction ou la réponse est invalide');
      }
      closeModal(); // Ferme le modal après l'ajout
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la carte:', error);
    }
  };

  return (
    <div className="modal-addcards">
      <div className="modal-content-addcards">
        <h2>Ajouter une carte</h2>
        <form onSubmit={handleSubmit}>
          <label>Titre</label>
          <input
            type="text"
            placeholder="Titre"
            value={newCard.title}
            onChange={(e) => setNewCard({ ...newCard, title: e.target.value })}
          />

          <label>Description</label>
          <textarea
            type="text"
            placeholder="Description"
            value={newCard.description}
            onChange={(e) => setNewCard({ ...newCard, description: e.target.value })}
          />

          <label>Rareté</label>
          <select
            value={newCard.rarity}
            onChange={(e) => setNewCard({ ...newCard, rarity: e.target.value })}
          >
            <option value="">Sélectionner la rareté</option>
            <option value="common">Commune</option>
            <option value="rare">Rare</option>
            <option value="mythique">Mythique</option>
            <option value="ultime">Ultime</option>
          </select>

          <label>URL de l'image</label>
          <input
            type="text"
            placeholder="URL de l'image"
            value={newCard.image_url}
            onChange={(e) => setNewCard({ ...newCard, image_url: e.target.value })}
          />

          <button type="submit" className="add-card">Ajouter</button>
          <button type="button" onClick={closeModal} className="close-addcard">Annuler</button>
        </form>
      </div>
    </div>
  );
};

export default AddCardModal;
