import React from "react";
import './Modal.scss';

const DeleteLiveControlModal = ({ closeModal, livecontrol, deleteLiveControl }) => {
    const handleDelete = async () => {
        console.log('ID du livecontrol à supprimer :', livecontrol._id);
        try {
            const response = await fetch(`https://api.supra-event.fr/api/staffboard/livecontrol/${livecontrol._id}`, {
                method: "DELETE"
            });

            if (response.ok) {
                deleteLiveControl(livecontrol._id);
                console.log("LiveControl supprimé avec succès");
                closeModal(); // Fermeture du modal après la suppression réussie
            } else {
                console.error("Erreur lors de la suppression du livecontrol");
            }
        } catch (error) {
            console.error("Erreur lors de la suppression :", error);
        }
    };

    return (
        <div className="modal-deletelivecontrols">
            <div className="modal-content-editlivecontrols">

            <h2>Supprimer le LiveControl</h2>
            <p>Es-tu sûr de vouloir supprimer le live control <strong>{livecontrol.title}</strong> ?</p>
            <div className="form-actions">
                <button className="btn-delete" onClick={handleDelete}>Supprimer</button>
                <button className="btn-cancel" onClick={closeModal}>Annuler</button>
            </div>
            </div>
        </div>
    );
};

export default DeleteLiveControlModal;
