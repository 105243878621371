import React from 'react';
import axios from 'axios';
import './Modal.scss'; // Assure-toi d'importer les styles

const DeleteStaffModal = ({ staffId, closeModal, updateStaff }) => {
  // URL de l'API pour supprimer un membre du staff
  const apiUrl = `https://api.supra-event.fr/api/pixionautes/staff/${staffId}`;

  const handleDelete = async () => {
    try {
      // Envoie une requête DELETE au serveur
      await axios.delete(apiUrl);
      console.log('Membre du staff supprimé avec succès');

      // Mise à jour du staff après la suppression
      if (typeof updateStaff === 'function') {
        updateStaff(prevStaff => prevStaff.filter(staff => staff._id !== staffId)); // Utilise _id pour la suppression
      } else {
        console.error('updateStaff n\'est pas une fonction');
      }
      closeModal(); // Ferme le modal après la suppression
    } catch (error) {
      console.error('Erreur lors de la suppression du membre du staff:', error);
    }
  };

  return (
    <div className="modal-deletestaffs">
      <div className="modal-content-deletestaffs">
        <h2>Confirmer la suppression</h2>
        <p>Êtes-vous sûr de vouloir supprimer ce membre du staff ?</p>
        <div className="button-overlay">
          <button onClick={handleDelete} className="delete-btn">Supprimer</button>
          <button onClick={closeModal} className="close-btn">Annuler</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteStaffModal;
