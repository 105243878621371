import React, { useState, useContext, useRef, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useLocation, Link } from 'react-router-dom'; // Importer Link ici
import images from '../../assets/staff'; // Importe tes images
import './Toolbar.scss';

const Toolbar = ({ userName, userLogo, onSearch, onOpenModal }) => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const [searchVisible, setSearchVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false); // État pour afficher/masquer le menu déroulant
  const dropdownRef = useRef(null); // Référence pour le menu déroulant

  const toggleSearch = () => {
    setSearchVisible(!searchVisible);
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    onSearch(query); // Appel de la fonction de recherche fournie par le parent
  };

  const getPageTitle = () => {
    const path = location.pathname;

    // HOME
    if (path.startsWith('/staffboard/home')) return 'ACCUEIL';
    if (path.startsWith('/staffboard/users')) return 'UTILISATEUR';

    // PixTournament
    if (path.startsWith('/staffboard/pixtournament/players')) return 'PLAYERS'; // Ajout pour Players
    if (path.startsWith('/staffboard/pixtournament/teams')) return 'TEAMS'; 
    if (path.startsWith('/staffboard/pixtournament/match')) return 'MATCH'; 
    if (path.startsWith('/staffboard/pixtournament/maps')) return 'MAPS'; 
    if (path.startsWith('/staffboard/pixtournament/brackets')) return 'BRACKETS'; 


    if (path.startsWith('/staffboard/pixionautes/cardgames')) return 'PIX CARD GAMES';
    if (path.startsWith('/staffboard/pixionautes/studiographique')) return 'STUDIO GRAPHIQUES';
    if (path.startsWith('/staffboard/pixionautes/staff')) return 'STAFF';
    if (path.startsWith('/staffboard/pixionautes/partner')) return 'PARTENAIRES';

    if (path.startsWith('/staffboard/livecontrol/news')) return 'NEWS';
    if (path.startsWith('/staffboard/livecontrol/ressources')) return 'RESSOURCES';
    if (path.startsWith('/staffboard/livecontrol/sanction')) return 'SANCTION';
    if (path.startsWith('/staffboard/livecontrol/overlay')) return 'OVERLAY';
    if (path.startsWith('/staffboard/livecontrol/pixbot')) return 'PIXBOT';


    return 'StaffBoard';
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const renderDropdownMenu = () => {
    const path = location.pathname;

    if (path.startsWith('/staffboard/home')) {
      return (
        <div className="dropdown show"> {/* Ajout de la classe show ici */}
          <Link to="/staffboard/home" className="dropdown-item">ACCUEIL</Link>
          <Link to="/staffboard/users" className="dropdown-item">USERS</Link>
        </div>
      );
    }

    if (path.startsWith('/staffboard/pixtournament')) {
      return (
        <div className="dropdown show"> {/* Ajout de la classe show ici */}
          <Link to="/staffboard/pixtournament/players" className="dropdown-item">PLAYERS</Link>
          <Link to="/staffboard/pixtournament/teams" className="dropdown-item">TEAMS</Link>
          <Link to="/staffboard/pixtournament/match" className="dropdown-item">MATCH</Link>
          <Link to="/staffboard/pixtournament/maps" className="dropdown-item">MAPS</Link>
        </div>
      );
    }

    if (path.startsWith('/staffboard/pixionautes')) {
        return (
          <div className="dropdown show"> {/* Ajout de la classe show ici */}
            <Link to="/staffboard/pixionautes/cardgames" className="dropdown-item">PIX CARD GAME</Link>
            <Link to="/staffboard/pixionautes/studiographique" className="dropdown-item">STUDIO GRAPHIQUE</Link>
            <Link to="/staffboard/pixionautes/staff" className="dropdown-item">STAFF</Link>
            <Link to="/staffboard/pixionautes/partner" className="dropdown-item">PARTENAIRES</Link>
          </div>
        );
      }    
      
    if (path.startsWith('/staffboard/livecontrol')) {
        return (
          <div className="dropdown show"> {/* Ajout de la classe show ici */}
            <Link to="/staffboard/livecontrol/news" className="dropdown-item">NEWS</Link>
            <Link to="/staffboard/livecontrol/ressources" className="dropdown-item">RESSOURCES</Link>
            <Link to="/staffboard/livecontrol/sanction" className="dropdown-item">SANCTION</Link>
            <Link to="/staffboard/livecontrol/overlay" className="dropdown-item">OVERLAY</Link>
            <Link to="/staffboard/livecontrol/pixbot" className="dropdown-item">PIX'BOT</Link>
          </div>
        );
      }
    return null; // Aucune option pour les autres pages
  };

  // Ferme le menu déroulant si on clique à l'extérieur
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setMenuVisible(false);
    }
  };

  // Écoutez les clics sur le document
  useEffect(() => {
    const handleTouchOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleTouchOutside); // Écoute les événements tactiles
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleTouchOutside); // Nettoyage
    };
  }, []);
  

  return (
    <div className="toolbar">
      <div className="toolbar-left">
        {user && user.url_profile ? (
          <img src={images[user.url_profile]} alt="Profile" className="profile-image-toolbar" />
        ) : (
          <div className="profile-image placeholder">No Image</div>
        )}
        <span className="user-name-toolbar">{userName}</span>
      </div>
      <div className="toolbar-center" onClick={toggleMenu} ref={dropdownRef}>
        <h2>{getPageTitle()}</h2>
        {menuVisible && renderDropdownMenu()} {/* Afficher le menu déroulant si visible */}
      </div>
      <div className="toolbar-right">
        {/* Rendre les icônes conditionnellement */}
        {!searchVisible && (
          <>
            <FontAwesomeIcon icon={faPlus} onClick={onOpenModal} className='add-button' />
            <FontAwesomeIcon icon={faMagnifyingGlass} onClick={toggleSearch} className='search-button' />
          </>
        )}
        
        {/* Champ de recherche */}
        {searchVisible && (
          <input 
            type="text" 
            placeholder="Rechercher dans la page..." 
            className="search-input" 
            onChange={handleSearch} 
            onBlur={() => setSearchVisible(false)} // Retire le champ de recherche si on clique à l'extérieur
          />
        )}
      </div>
    </div>
  );
};

export default Toolbar;
