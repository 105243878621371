import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Modal.scss'; // Assure-toi d'avoir les styles appropriés

const EditResourceModal = ({ resource, closeModal, updateResource }) => {
  const [formData, setFormData] = useState({
    service: '',
    command: '',
    description: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (resource) {
      // Trouver la commande sélectionnée à partir de l'ID
      const commandToEdit = resource.Commandes.flatMap(c =>
        c.commands.filter(cmd => cmd._id === resource.selectedCommandId)
      )[0];
      if (commandToEdit) {
        setFormData({
          service: resource.service,
          command: commandToEdit.command,
          description: commandToEdit.description
        });
      }
    }
  }, [resource]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`https://api.supra-event.fr/api/staffboard/ressources/${resource._id}/commands/${resource.selectedCommandId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          command: formData.command,
          description: formData.description
        })
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la mise à jour de la commande');
      }

      const updatedResource = await response.json();
      updateResource(updatedResource);
      closeModal();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-editressources">
      <div className="modal-content-editressources">
        <h2>Modifier Commande</h2>
        <form className='edit-modal' onSubmit={handleSubmit}>
          <div className="command-fields">
            <label htmlFor="command">Commande</label>
            <input
              type="text"
              id="command"
              name="command"
              value={formData.command}
              onChange={handleChange}
              required
            />
          </div>
          <div className="command-fields">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </div>
          {error && <p className="error">{error}</p>}
          <div className="modal-actions">
            <button className='submit-editcommands' type="submit" disabled={loading}>
              {loading ? 'Enregistrement...' : 'Enregistrer'}
            </button>
            <button className='close-editcommands' type="button" onClick={closeModal}>Annuler</button>
          </div>
        </form>
      </div>
    </div>
  );
};

EditResourceModal.propTypes = {
  resource: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateResource: PropTypes.func.isRequired
};

export default EditResourceModal;
