import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../../../../components/Navbar/Navbar';
import AddStaffModal from './Modals/AddStaffModal';
import EditStaffModal from './Modals/EditStaffModal';
import DeleteStaffModal from './Modals/DeleteStaffModal';
import './Staff.scss';
import Toolbar from '../../../../components/Toolbar/Toolbar';

const Staff = () => {
  const [showAddStaffModal, setShowAddStaffModal] = useState(false);
  const [showEditStaffModal, setShowEditStaffModal] = useState(null);
  const [showDeleteStaffModal, setShowDeleteStaffModal] = useState(null);
  const [staffMembers, setStaffMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');  // État pour la recherche

  // URL de base pour les appels API
  const apiUrl = 'https://api.supra-event.fr/api/pixionautes/staff';

  // Charger les membres du personnel depuis l'API
  const fetchStaffMembers = async () => {
    try {
      const response = await axios.get(apiUrl);
      setStaffMembers(response.data);
    } catch (error) {
      console.error('Erreur lors du chargement des membres du personnel:', error);
    }
  };

  // Appeler fetchStaffMembers lors du premier rendu
  useEffect(() => {
    fetchStaffMembers();
  }, []);

  // Éditer un membre du personnel existant
  const editStaff = async (staff) => {
    try {
      const response = await axios.put(`${apiUrl}/${staff._id}`, staff);
      setStaffMembers(staffMembers.map(s => (s._id === staff._id ? response.data : s)));
    } catch (error) {
      console.error('Erreur lors de l\'édition du membre du personnel:', error);
    }
  };

  // Ouvrir/fermer modals
  const openAddStaffModal = () => setShowAddStaffModal(true);
  const openEditStaffModal = (staff) => setShowEditStaffModal(staff);
  const openDeleteStaffModal = (staffId) => setShowDeleteStaffModal(staffId);
  const closeModals = () => {
    setShowAddStaffModal(false);
    setShowEditStaffModal(null);
    setShowDeleteStaffModal(null);
  };

  // Filtrer les membres du personnel en fonction du terme de recherche
  const filteredStaffMembers = staffMembers.filter(staff =>
    staff.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <section className="section-staff">
      <Navbar />
      <Toolbar onOpenModal={openAddStaffModal} onSearch={setSearchTerm} />

      <div className="staff-container">
        {filteredStaffMembers.length > 0 ? (
          filteredStaffMembers.map((staff) => (
            <div key={staff._id} className="staff-card">
              <h2 className="staff-name">{staff.name}</h2>
              {staff.imageUrl ? (  // Utilisation de staff.imageUrl pour afficher l'image
                <img src={staff.imageUrl} alt={staff.name} className="staff-media" />
              ) : (
                <p>Pas d'image disponible</p>  // Message si aucune image n'est disponible
              )}
              <p className="staff-description">{staff.description}</p>

              {/* Boutons pour éditer et supprimer */}
              <div className="staff-actions">
                <button onClick={() => openEditStaffModal(staff)} className="edit-btn">Éditer</button>
                <button onClick={() => openDeleteStaffModal(staff._id)} className="delete-btn">Supprimer</button>
              </div>
            </div>

          ))
        ) : (
          <p>Aucun membre du personnel trouvé.</p>  // Message si aucun membre ne correspond à la recherche
        )}
      </div>

      {/* Modals */}
      {showAddStaffModal && <AddStaffModal closeModal={closeModals} updateStaff={fetchStaffMembers} />}
      {showEditStaffModal && (
        <EditStaffModal
          staff={showEditStaffModal}
          closeModal={closeModals}
          editStaff={editStaff} // Passer la fonction editStaff ici
        />
      )}
      {showDeleteStaffModal && (
        <DeleteStaffModal
          staffId={showDeleteStaffModal}
          closeModal={closeModals}
          updateStaff={fetchStaffMembers} // Passer la fonction updateStaff ici pour mettre à jour les membres
        />
      )}
    </section>
  );
};

export default Staff;
