import React, { useState } from 'react';
import axios from 'axios';
import './Modal.scss'; // Assure-toi que ce fichier existe et contient les styles nécessaires

const EditUserModal = ({ user, closeModal, updateUser }) => {
  const [username, setUsername] = useState(user.username);
  const [password, setPassword] = useState(user.password);
  const [urlProfile, setUrlProfile] = useState(user.url_profile);
  const [role, setRole] = useState(user.role);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedUser = {
        username,
        password,
        url_profile: urlProfile,
        role
      };
      const response = await axios.put(`https://api.supra-event.fr/api/users/${user._id}`, updatedUser);
      updateUser(response.data); // Met à jour la liste des utilisateurs après l'édition
      closeModal(); // Ferme le modal après la mise à jour
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'utilisateur:', error);
    }
  };

  return (
    <div className="modal-editusers">
      <div className="modal-content-editusers">
        <h2>Éditer l'Utilisateur</h2>
        <form onSubmit={handleSubmit}>
          <label>Nom d'utilisateur</label>
          <input 
            type="text" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            required 
          />
          <label>Mot de passe</label>
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
          <label>URL du Profil</label>
          <input 
            type="text" 
            value={urlProfile} 
            onChange={(e) => setUrlProfile(e.target.value)} 
            required 
          />
          <label>Rôle</label>
          <select 
            value={role} 
            onChange={(e) => setRole(e.target.value)} 
            required
          >
            <option value="" disabled>Sélectionner un rôle</option>
            <option value="admin">Admin</option>
            <option value="pixetmotion">Pixetmotion</option>
            <option value="staffpix">Staffpix</option>
            <option value="peresourass">peresourass</option>
          </select>
          <button className='save-edituser' type="submit">Sauvegarder</button>
          <button className='close-edituser' type="button" onClick={closeModal}>Fermer</button>
        </form>
      </div>
    </div>
  );
};

export default EditUserModal;
