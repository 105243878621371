import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Modal.scss';

const AddPlayerModal = ({ closeModal, updatePlayerList }) => {
  const [name, setName] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [team, setTeam] = useState('');
  const [kills, setKills] = useState('');
  const [deaths, setDeaths] = useState('');
  const [teams, setTeams] = useState([]);

  // Récupérer les équipes depuis l'API
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get('https://api.supra-event.fr/api/tournament/teams');
        setTeams(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des équipes:', error);
      }
    };

    fetchTeams();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newPlayer = {
        name,
        profileImage,
        team,
        kills: parseInt(kills, 10),
        deaths: parseInt(deaths, 10),
      };
      const response = await axios.post('https://api.supra-event.fr/api/tournament/players', newPlayer);
      updatePlayerList(response.data); // Passer le joueur ajouté au parent
      closeModal();
    } catch (error) {
      console.error('Erreur lors de l\'ajout du joueur:', error);
    }
  };

  return (
    <div className="modal-addplayers">
      <div className="modal-content-addplayers">
        <h2>Ajouter un Joueur</h2>
        <form onSubmit={handleSubmit}>
          <label>Pseudo</label>
          <input 
            type="text" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            required 
          />
          <label>Url Image</label>
          <input 
            type="text" 
            value={profileImage} 
            onChange={(e) => setProfileImage(e.target.value)} 
            required 
          />
          <label>Team</label>
          <select 
            value={team} 
            onChange={(e) => setTeam(e.target.value)} 
            required
          >
            {teams.map((team) => (
              <option key={team._id} value={team.name}>
                {team.tag}
              </option>
            ))}
          </select>
          <label>Kills</label>
          <input 
            type="number" 
            value={kills} 
            onChange={(e) => setKills(e.target.value)} 
            required 
          />
          <label>Deaths</label>
          <input 
            type="number" 
            value={deaths} 
            onChange={(e) => setDeaths(e.target.value)} 
            required 
          />
          <button className='addplayers' type="submit">Ajouter</button>
          <button className='closeplayers' type="button" onClick={closeModal}>Fermer</button>
        </form>
      </div>
    </div>
  );
};

export default AddPlayerModal;
