import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../../../../components/Navbar/Navbar';
import AddStudioModal from './Modals/AddStudioModal';
import EditStudioModal from './Modals/EditStudioModal';
import DeleteStudioModal from './Modals/DeleteStudioModal';
import './studio.scss';
import Toolbar from '../../../../components/Toolbar/Toolbar';

const StudioGraphique = () => {
  const [showAddStudioModal, setShowAddStudioModal] = useState(false);
  const [showEditStudioModal, setShowEditStudioModal] = useState(null);
  const [showDeleteStudioModal, setShowDeleteStudioModal] = useState(null);
  const [studios, setStudios] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');  // État pour stocker le terme de recherche

  const apiUrl = 'https://api.supra-event.fr/api/pixionautes/studio';

  const fetchStudios = async () => {
    try {
      const response = await axios.get(apiUrl);
      setStudios(response.data);
    } catch (error) {
      console.error('Erreur lors du chargement des studios:', error);
    }
  };

  useEffect(() => {
    fetchStudios();
  }, []);

  const editStudio = async (studio) => {
    try {
      const response = await axios.put(`${apiUrl}/${studio._id}`, studio);
      setStudios(studios.map(s => (s._id === studio._id ? response.data : s)));
    } catch (error) {
      console.error('Erreur lors de l\'édition du studio:', error);
    }
  };

  const openAddStudioModal = () => setShowAddStudioModal(true);
  const openEditStudioModal = (studio) => setShowEditStudioModal(studio);
  const openDeleteStudioModal = (studioId) => setShowDeleteStudioModal(studioId);
  const closeModals = () => {
    setShowAddStudioModal(false);
    setShowEditStudioModal(null);
    setShowDeleteStudioModal(null);
  };

  // Mettre à jour le terme de recherche lorsque l'utilisateur tape
  const handleSearch = (term) => {
    setSearchTerm(term); // Met à jour l'état avec la recherche
  };

  // Filtrer les studios en fonction du terme de recherche
  const filteredStudios = studios.filter(studio =>
    studio.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderMedia = (mediaUrl) => {
    if (!mediaUrl) {
      return <p>URL du média non définie</p>;
    }
    console.log('Media URL:', mediaUrl);
    const fileExtension = mediaUrl.split('.').pop().toLowerCase();
    if (fileExtension === 'webp') {
      return <img src={mediaUrl} alt="Studio" className="studio-media" />;
    } else if (fileExtension === 'webm') {
      return (
        <video controls className="studio-media">
          <source src={mediaUrl} type="video/webm" />
          Votre navigateur ne supporte pas la vidéo.
        </video>
      );
    } else {
      return <p>Type de média non supporté</p>;
    }
  };

  return (
    <section className="section-studio">
      <Navbar />
      <Toolbar onOpenModal={openAddStudioModal} onSearch={handleSearch} /> {/* Appel de la fonction handleSearch */}
      <div className="studio-container">
        {filteredStudios.length > 0 ? (
          filteredStudios.map((studio) => (
            <div key={studio._id} className="studio-card">
              <h2 className="studio-title">{studio.title}</h2>
              {renderMedia(studio.imageUrl)}
              <p className="studio-description">{studio.description}</p>
              <div className="studio-actions">
                <button onClick={() => openEditStudioModal(studio)} className="edit-btn">Éditer</button>
                <button onClick={() => openDeleteStudioModal(studio._id)} className="delete-btn">Supprimer</button>
              </div>
            </div>
          ))
        ) : (
          <p>Aucun studio trouvé.</p>
        )}
      </div>
      {showAddStudioModal && <AddStudioModal closeModal={closeModals} updateStudios={fetchStudios} />}
      {showEditStudioModal && (
        <EditStudioModal
          studio={showEditStudioModal}
          closeModal={closeModals}
          editStudio={editStudio}
        />
      )}
      {showDeleteStudioModal && (
        <DeleteStudioModal
          studioId={showDeleteStudioModal}
          closeModal={closeModals}
          updateStudios={fetchStudios}
        />
      )}
    </section>
  );
};

export default StudioGraphique;
