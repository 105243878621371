import React, { useState } from 'react';
import axios from "axios";
import './Modal.scss';

const EditPixBotModal = ({ pixbot, closeModal, updatePixBot }) => {
    const [pixbotTrigger, setPixBotTrigger] = useState(pixbot.trigger);
    const [pixbotAlias, setPixBotAlias] = useState(pixbot.alias);
    const [pixbotMessage, setPixBotMessage] = useState(pixbot.message);
    const [pixbotTypeMessage, setPixBotTypeMessage] = useState(pixbot.typeMessage || 'msg_normal');
    const [pixbotRestrictions, setPixBotRestrictions] = useState({
        moderateur: pixbot.restrictions?.moderateur || false,
        vip: pixbot.restrictions?.vip || false,
        subs: pixbot.restrictions?.subs || false,
    });

    // Gérer les changements dans les cases à cocher pour les restrictions
    const handleRestrictionChange = (e) => {
        const { name, checked } = e.target;
        setPixBotRestrictions(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Vérifier si aucune restriction n'est cochée
        const noRestrictions = !pixbotRestrictions.moderateur && !pixbotRestrictions.vip && !pixbotRestrictions.subs;

        // Créer l'objet mis à jour
        const updatedPixBotData = {
            trigger: pixbotTrigger,
            alias: pixbotAlias,
            message: pixbotMessage,
            typeMessage: pixbotTypeMessage,
            restrictions: noRestrictions ? null : pixbotRestrictions  // Si pas de restrictions, on met `null`
        };

        try {
            const response = await axios.put(`https://api.supra-event.fr/api/staffboard/pixbot/${pixbot._id}`, updatedPixBotData);
            updatePixBot(response.data);
            closeModal(); // Fermer le modal après mise à jour
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la commande', error);
        }
    };

    return (
        <div className="modal-editpixbot">
            <div className="modal-content-editpixbot">
                <h2>Éditer la commande</h2>
                <form onSubmit={handleSubmit}>
                    <label>Déclencheur</label>
                    <textarea
                        value={pixbotTrigger}
                        onChange={(e) => setPixBotTrigger(e.target.value)}
                        required
                    />

                    <label>Alias</label>
                    <textarea
                        value={pixbotAlias}
                        onChange={(e) => setPixBotAlias(e.target.value)}
                    />

                    <label>Type de message</label>
                    <select
                        name="type_message"
                        id="type_message"
                        value={pixbotTypeMessage}
                        onChange={(e) => setPixBotTypeMessage(e.target.value)}
                    >
                        <option value="italic">Italique</option>
                        <option value="msg_normal">Message Normal</option>
                    </select>

                    <label>Restrictions</label>
                    <div className="restriction">
                        <label>
                            <input 
                                type="checkbox"
                                name="moderateur"
                                checked={pixbotRestrictions.moderateur}
                                onChange={handleRestrictionChange}
                            /> Mod
                        </label>
                        <label>
                            <input 
                                type="checkbox"
                                name="vip"
                                checked={pixbotRestrictions.vip}
                                onChange={handleRestrictionChange}
                            /> VIP
                        </label>
                        <label>
                            <input 
                                type="checkbox"
                                name="subs"
                                checked={pixbotRestrictions.subs}
                                onChange={handleRestrictionChange}
                            /> Subs
                        </label>
                    </div>

                    <label>Message</label>
                    <textarea
                        value={pixbotMessage}
                        onChange={(e) => setPixBotMessage(e.target.value)}
                        required
                    />
                    
                    <button className='save-editpixbot' type="submit">Sauvegarder</button>
                    <button className='close-editpixbot' type="button" onClick={closeModal}>Fermer</button>
                </form>
            </div>
        </div>
    );
};

export default EditPixBotModal;
