import React, { useState } from 'react';
import axios from 'axios';
import './Modal.scss'; // Assure-toi d'importer les styles pour le modal

const AddStudioModal = ({ closeModal, updateStudios }) => {
  const [newStudio, setNewStudio] = useState({
    title: '',
    description: '',
    imageUrl: '' // Stocker l'URL de l'image ici
  });
  const [error, setError] = useState('');

  const apiUrl = 'https://api.supra-event.fr/api/pixionautes/studio'; // Remplace par ton URL API

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newStudio.imageUrl) {
      setError("Veuillez entrer l'URL de l'image.");
      return;
    }

    try {
      const response = await axios.post(apiUrl, newStudio, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data && typeof updateStudios === 'function') {
        updateStudios(prevStudios => [...prevStudios, response.data]); // Mise à jour de la liste des studios
      }
      closeModal(); // Fermer le modal après l'ajout
    } catch (error) {
      console.error("Erreur lors de l'ajout du studio:", error);
      setError("Une erreur est survenue lors de l'ajout.");
    }
  };

  return (
    <div className="modal-addstudios">
      <div className="modal-content-addstudios">
        <h2>Ajouter un studio</h2>
        {error && <p className="error-message">{error}</p>} {/* Affichage des erreurs */}
        <form onSubmit={handleSubmit}>
          <label>Titre</label>
          <input
            type="text"
            placeholder="Titre"
            value={newStudio.title}
            onChange={(e) => setNewStudio({ ...newStudio, title: e.target.value })}
          />

          <label>Description</label>
          <textarea
            placeholder="Description"
            value={newStudio.description}
            onChange={(e) => setNewStudio({ ...newStudio, description: e.target.value })}
          />

          <label>URL de l'image</label>
          <input
            type="text"
            placeholder="https://exemple.com/image.jpg"
            value={newStudio.imageUrl}
            onChange={(e) => setNewStudio({ ...newStudio, imageUrl: e.target.value })}
          />

          <button type="submit" className="add-studio">Ajouter</button>
          <button type="button" onClick={closeModal} className="close-studio">Annuler</button>
        </form>
      </div>
    </div>
  );
};

export default AddStudioModal;
