import React, { useState } from "react";
import axios from 'axios';
import './Modal.scss'

const AddPixBotModal = ({ closeModal, updatePixBotList }) => {
    const [pixbotTrigger, setPixBotTrigger] = useState('');
    const [pixbotAlias, setPixBotAlias] = useState('');
    const [pixbotMessage, setPixBotMessage] = useState('');
    const [pixbotTypeMessage, setPixBotTypeMessage] = useState('msg_normal');  // Par défaut à "Message Normal"
    const [pixbotRestrictions, setPixBotRestrictions] = useState({
        moderateur: false,
        vip: false,
        subs: false
    });

    // Gérer les changements dans les cases à cocher
    const handleRestrictionChange = (e) => {
        const { name, checked } = e.target;
        setPixBotRestrictions(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Vérifier si aucune restriction n'est cochée
        const noRestrictions = !pixbotRestrictions.moderateur && !pixbotRestrictions.vip && !pixbotRestrictions.subs;
        
        // Créer l'objet à envoyer
        const newPixBot = {
            trigger: pixbotTrigger,
            alias: pixbotAlias,
            message: pixbotMessage,
            typeMessage: pixbotTypeMessage,
            restrictions: noRestrictions ? null : pixbotRestrictions  // Si aucune restriction, on met `null`
        };

        try {
            await axios.post('https://api.supra-event.fr/api/staffboard/pixbot', newPixBot);
            updatePixBotList();
            closeModal();
        } catch (error) {
            console.error('Erreur lors de l\'ajout de la commande', error);
        }
    };

    return (
        <div className="modal-addpixbot">
            <div className="modal-content-addpixbot">
                <h2>Ajouter une Commande</h2>
                <form onSubmit={handleSubmit} >

                    <label>Déclencheur</label>
                    <textarea
                        value={pixbotTrigger}
                        onChange={(e) => setPixBotTrigger(e.target.value)}
                        required
                    />

                    <label>Alias</label>
                    <textarea
                        value={pixbotAlias}
                        onChange={(e) => setPixBotAlias(e.target.value)}
                    />

                    <label>Type de message</label>
                    <select
                        name="type_message"
                        id="type_message"
                        value={pixbotTypeMessage}
                        onChange={(e) => setPixBotTypeMessage(e.target.value)}
                    >
                        <option value="italic">Italique</option>
                        <option value="msg_normal">Message Normal</option>
                    </select>

                    <label>Restrictions</label>
                    <div className="restriction">
                        <label>
                            <input 
                                type="checkbox"
                                name="moderateur"
                                checked={pixbotRestrictions.moderateur}
                                onChange={handleRestrictionChange}
                            /> Mod
                        </label>
                        <label>
                            <input 
                                type="checkbox"
                                name="vip"
                                checked={pixbotRestrictions.vip}
                                onChange={handleRestrictionChange}
                            /> VIP
                        </label>
                        <label>
                            <input 
                                type="checkbox"
                                name="subs"
                                checked={pixbotRestrictions.subs}
                                onChange={handleRestrictionChange}
                            /> Subs
                        </label>
                    </div>

                    <label>Message</label>
                    <textarea
                        value={pixbotMessage}
                        onChange={(e) => setPixBotMessage(e.target.value)}
                        required
                    />

                    <button className="add-pixbot" type="submit">Ajouter</button>
                    <button className="close-pixbot" type="button" onClick={closeModal}>Fermer</button>
                </form>
            </div>
        </div>
    );
};

export default AddPixBotModal;
