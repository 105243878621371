import React, { useState, useEffect } from "react";
import './Modal.scss';

const EditSanctionModal = ({ closeModal, sanction, updateSanction }) => {
    const [formData, setFormData] = useState({ network: '', username: '', moderator: '', type: '', reason: '' });

    useEffect(() => {
        if (sanction) {
            setFormData({
                network: sanction.network || '',
                username: sanction.username || '',
                moderator: sanction.moderator || '',
                type: sanction.type || '',
                reason: sanction.reason || ''
            });
        }
    }, [sanction]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://api.supra-event.fr/api/staffboard/sanctions/${sanction._id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                updateSanction({ ...sanction, ...formData });  // Mise à jour locale
                closeModal();  // Ferme le modal
            } else {
                console.error("Erreur lors de la mise à jour de la sanction");
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour:", error);
        }
    };

    return (
        <div className="modal-editsanctions">
            <div className="modal-content-editsanctions">
                <h2>Modifier la sanction</h2>
                <form onSubmit={handleSubmit}>
                    <label>
                        Réseau
                        <select
                            name="network"
                            value={formData.network}
                            onChange={handleChange}
                            required
                        >
                            <option value="Twitch">Twitch</option>
                            <option value="Discord">Discord</option>
                            <option value="Tiktok">TikTok</option>
                        </select>
                    </label>
                    <label>
                        Nom d'utilisateur
                        <input
                            type="text"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Modérateur
                        <input
                            type="text"
                            name="moderator"
                            value={formData.moderator}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Type de sanction
                        <input
                            type="text"
                            name="type"
                            value={formData.type}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Raison
                        <textarea
                            name="reason"
                            value={formData.reason}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <div className="form-actions">
                        <button className="submit-editcommands" type="submit">Sauvegarder</button>
                        <button className="close-editcommands" type="button" onClick={closeModal}>Annuler</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditSanctionModal;
