import React from 'react';
import axios from 'axios';
import './Modal.scss'; // Assure-toi d'importer les styles

const DeleteCardModal = ({ cardId, closeModal, updateCards }) => {
  // URL de l'API pour supprimer la carte
  const apiUrl = `https://api.supra-event.fr/api/pixionautes/cardgames/${cardId}`;

  const handleDelete = async () => {
    try {
      // Envoie une requête DELETE au serveur
      await axios.delete(apiUrl);
      console.log('Carte supprimée avec succès');

      // Mise à jour des cartes après la suppression
      if (typeof updateCards === 'function') {
        updateCards(prevCards => prevCards.filter(card => card._id !== cardId)); // Utilise _id pour la suppression
      } else {
        console.error('updateCards n\'est pas une fonction');
      }
      closeModal(); // Ferme le modal après la suppression
    } catch (error) {
      console.error('Erreur lors de la suppression de la carte:', error);
    }
  };

  return (
    <div className="modal-deletecards">
      <div className="modal-content-deletecards">
        <h2>Confirmer la suppression</h2>
        <p>Êtes-vous sûr de vouloir supprimer cette carte ?</p>
        <div className="button-overlay">
          <button onClick={handleDelete} className="delete-btn">Supprimer</button>
          <button onClick={closeModal} className="close-btn">Annuler</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteCardModal;
