import React, { useEffect, useState } from "react";
import './Overlay.scss';
import Navbar from "../../../../components/Navbar/Navbar";
import AddLiveControlModal from './Modal/AddOverlayModal';
import DeleteLiveControlModal from './Modal/DeleteOverlayModal';
import EditLiveControlModal from './Modal/EditOverlayModal';
import Toolbar from "../../../../components/Toolbar/Toolbar";

const LiveControl = () => {
    const [livecontrols, SetLiveControls] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [livecontrolToDelete, SetLiveControlToDelete] = useState(null);
    const [livecontrolToEdit, setLiveControlToEdit] = useState(null);
    const [searchTerm, setSearchTerm] = useState(''); // État pour le terme de recherche

    const fetchLiveControls = async () => {
        try {
            const response = await fetch("https://api.supra-event.fr/api/staffboard/livecontrol");
            const data = await response.json();
            SetLiveControls(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des livecontrols:", error);
        }
    };

    useEffect(() => {
        fetchLiveControls();
    }, []);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const openDeleteModal = (livecontrol) => {
        SetLiveControlToDelete(livecontrol);
        setDeleteModalOpen(true);
    };

    const openEditModal = (livecontrol) => {
        setLiveControlToEdit(livecontrol);
        setEditModalOpen(true);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        SetLiveControlToDelete(null);  // Réinitialise l'élément à supprimer
    };
    
    const closeEditModal = () => {
        setEditModalOpen(false);
        setLiveControlToEdit(null);
    };

    const handleDeleteLiveControl = (livecontrolId) => {
        SetLiveControls(prevLiveControls => prevLiveControls.filter(livecontrol => livecontrol._id !== livecontrolId));
        closeDeleteModal();
    }

    const handleUpdateLiveControl = (updatedLiveControl) => {
        SetLiveControls(prevLiveControls =>
            prevLiveControls.map(livecontrol =>
                livecontrol._id === updatedLiveControl._id ? updatedLiveControl : livecontrol
            )
        );
        closeEditModal();
    };

    // Filtrer les livecontrols en fonction du terme de recherche
    const filteredLiveControls = livecontrols.filter(livecontrol =>
        livecontrol.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <section className="section-livecontrol">
            <Navbar/>
            <Toolbar onOpenModal={toggleModal} onSearch={setSearchTerm} /> {/* Passer setSearchTerm pour mettre à jour le terme de recherche */}

            <div className="livecontrol-container">
                {filteredLiveControls.length > 0 ? (
                    filteredLiveControls.map((livecontrol, index) => (
                        <div className="livecontrol-card" key={index}>
                            <h2>{livecontrol.title}</h2>
                            <div className="livecontrol-info">
                                <div className="livecontrol-data">
                                    <p>{livecontrol.description}</p>
                                    <a href={livecontrol.redirection}> Redirection</a>
                                </div>
                                <button className="btn-edit-livecontrol" onClick={() => openEditModal(livecontrol)}>
                                    Éditer
                                </button>
                                <button className="btn-delete-livecontrol" onClick={() => openDeleteModal(livecontrol)}>
                                    Supprimer
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>Aucun live control trouvé.</p>
                )}
            </div>
            {modalOpen && <AddLiveControlModal closeModal={toggleModal} AddLiveControl={fetchLiveControls} /> }
            {deleteModalOpen && livecontrolToDelete && (
                <DeleteLiveControlModal closeModal={closeDeleteModal} livecontrol={livecontrolToDelete} deleteLiveControl={handleDeleteLiveControl} />
            )}
            {editModalOpen && livecontrolToEdit && (
                <EditLiveControlModal closeModal={closeEditModal} livecontrol={livecontrolToEdit} updateLiveControl={handleUpdateLiveControl} />
            )}
        </section>
    )
};

export default LiveControl;
