import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Modal.scss';

const EditTeamModal = ({ team, closeModal, allPlayers, updateTeams }) => {
  const [name, setName] = useState(team.name || '');
  const [logo, setLogo] = useState(team.logo || '');
  const [tag, setTag] = useState(team.tag || '');
  const [captainId, setCaptainId] = useState(team.captain?.id || '');

  useEffect(() => {
    setName(team.name || '');
    setLogo(team.logo || '');
    setTag(team.tag || '');
    setCaptainId(team.captain?.id || '');
  }, [team]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedTeam = {
        name,
        logo,
        tag,
        captain: {
          id: captainId, // Assurez-vous que c'est un string
          name: allPlayers.find(player => player._id === captainId)?.name || ''
        }
      };
  
      const response = await axios.put(`https://api.supra-event.fr/api/tournament/teams/${team._id}`, updatedTeam);
      updateTeams(response.data); // Met à jour la liste des équipes
      closeModal();
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'équipe:', error);
    }
  };
  

  return (
    <div className="modal-editteams">
      <div className="modal-content-editteams">
        <h2>Éditer l'équipe</h2>
        <form onSubmit={handleSubmit}>
          <label>Nom de l'équipe</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <label>Logo URL</label>
          <input
            type="text"
            value={logo}
            onChange={(e) => setLogo(e.target.value)}
          />
          <label>Tag</label>
          <input
            type="text"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
            required
          />
          <label>Capitaine</label>
          <select
            value={captainId}
            onChange={(e) => setCaptainId(e.target.value)}
          >
            <option value="">Sélectionner un capitaine</option>
            {allPlayers.map((player) => (
              <option key={player._id} value={player._id}>
                {player.name}
              </option>
            ))}
          </select>
          <button className='save-editteams' type="submit">Sauvegarder</button>
          <button className='close-editteams' type="button" onClick={closeModal}>Fermer</button>
        </form>
      </div>
    </div>
  );
};

export default EditTeamModal;
