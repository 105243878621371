import React, { useState } from 'react';
import axios from 'axios';
import './Modal.scss';

const EditNewsModal = ({ news, closeModal, updateNews }) => {
  const [newsText, setNewsText] = useState(news.text);
  const [newsColor, setNewsColor] = useState(news.color);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedNews = {
        text: newsText,
        color: newsColor
      };
      const response = await axios.put(`https://api.supra-event.fr/api/staffboard/news/${news._id}`, updatedNews);
      updateNews(response.data); // Met à jour la liste des actualités après l'édition
      closeModal();
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'actualité:', error);
    }
  };

  return (
    <div className="modal-editnews">
      <div className="modal-content-editnews">
        <h2>Éditer l'actualité</h2>
        <form onSubmit={handleSubmit}>
          <label>Contenu de l'actualité</label>
          <textarea 
            value={newsText} 
            onChange={(e) => setNewsText(e.target.value)} 
            required 
          />

          <label>Couleur de l'actualité</label>
          <select 
            value={newsColor} 
            onChange={(e) => setNewsColor(e.target.value)} 
            required
          >
            <option value="rgb(255, 0, 166)">News Gaming</option>
            <option value="rgb(255, 0, 0)">Message Communauté</option>
            <option value="yellow">Annonce</option>
          </select>

          <button className='save-editnews' type="submit">Sauvegarder</button>
          <button className='close-editnews' type="button" onClick={closeModal}>Fermer</button>
        </form>
      </div>
    </div>
  );
};

export default EditNewsModal;
