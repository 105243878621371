import React, { useState, useEffect } from 'react';
import './Modal.scss';

const DeleteResourceModal = ({ closeModal, resourceId, commandId, removeCommandFromList }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [command, setCommand] = useState(null);

  useEffect(() => {
    const fetchCommand = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://api.supra-event.fr/api/staffboard/ressources/${resourceId}/commands/${commandId}`);
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération de la commande');
        }
        const data = await response.json();
        setCommand(data);
      } catch (err) {
        setError('Erreur lors de la récupération de la commande');
      } finally {
        setLoading(false);
      }
    };

    if (resourceId && commandId) {
      fetchCommand();
    }
  }, [resourceId, commandId]);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await removeCommandFromList(resourceId, commandId);
    } catch (err) {
      setError('Erreur lors de la suppression de la commande');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-deleteressources">
      <div className="modal-content-deleteressources">
        <h2>Confirmation de suppression</h2>
        {loading && <p>Chargement...</p>}
        {error && <p className="error">{error}</p>}
        {command && (
          <>
            <p>Êtes-vous sûr de vouloir supprimer la commande <strong>{command.command}</strong> ?</p>
            <div className="modal-actions">
              <button className="btn-delete" onClick={handleDelete}>Supprimer</button>
              <button className="btn-cancel" onClick={closeModal}>Annuler</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DeleteResourceModal;
