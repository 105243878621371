import React from "react";
import './Brackets.scss';
import Navbar from "../../../../components/Navbar/Navbar";
import Toolbar from "../../../../components/Toolbar/Toolbar"


function Bracket() {

    return (
        <section className="section-brackets">
            <Navbar/>
            <Toolbar />

            <h2>BRACKETS</h2>
        </section>
    )
};

export default Bracket;