import React, { useContext } from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import './AdminDashboard.scss'; 
import { AuthContext } from '../../../context/AuthContext/AuthContext'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye, faPeopleGroup, faUser } from '@fortawesome/free-solid-svg-icons';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom'; // Importer useNavigate
import Toolbar from '../../../components/Toolbar/Toolbar';

const PixetMotionDashboard = () => {
  const { user } = useContext(AuthContext); // Récupérer l'utilisateur et son rôle

  const navigate = useNavigate(); // Initialiser useNavigate

  // Fonction pour gérer la navigation
  const handleTournamentClick = () => {
    navigate('/staffboard/pixtournament/players'); // Chemin vers la page PIX-TOURNAMENT
  };

  // Autres fonctions de gestion des clics pour les cartes si nécessaire
  const handlePixionautesClick = () => {
    navigate('/staffboard/pixionautes/cardgames'); // Chemin vers la page PIXIONAUTE
  };

  const handleLiveControlClick = () => {
    navigate('/staffboard/livecontrol/news'); // Chemin vers la page LIVECONTROL
  };

  const handleUserClick = () => {
    navigate('/staffboard/users')
  }
  return (
    <div className="pixetmotion-dashboard">
      <Navbar />
      <Toolbar />
      <div className="dashboard-content">

        <section className="dashboard-section">
          {/* CARD PIX TOURNAMENT */}
          <div className="card tournament-card" onClick={handleTournamentClick} >
            <FontAwesomeIcon icon={faBullseye} className='card-icon' /> 
            <h3>PIX-TOURNAMENT</h3>
            <p className="card-description">Gérez les tournois : ajoutez et modifiez les équipes, organisez des matchs et consultez les résultats.</p>
          </div>

          {/* CARD PIXIONAUTES */}
          <div className="card pixionautes-card" onClick={handlePixionautesClick}>
            <FontAwesomeIcon icon={faPeopleGroup} className='card-icon' /> 
            <h3>PIXIONAUTES</h3>
            <p className="card-description">Gérez le site web lespixionautes.fr : ajoutez et modifiez certaines sections pour une expérience utilisateur optimale.</p>
          </div>

          {/* CARD STAFFBOARD */}
          <div className="card livecontrol-cardhome" onClick={handleLiveControlClick}>
            <FontAwesomeIcon icon={faTwitch} className='card-icon' /> 
            <h3>LIVECONTROL</h3>
            <p className="card-description">Gérez le live : ajoutez des news, soumettez vos rapports de sanctions, restez informé des commandes disponibles et accédez à la liste des overlays.</p>
          </div>

          {/* CARD USERS */}
          {(user?.role === 'admin') && (
            <div className="card users-cardhome" onClick={handleUserClick}>
            <FontAwesomeIcon icon={faUser} className='card-icon' /> 
            <h3>USERS</h3>
            <p className="card-description">Gérez le utilisateur : ajoutez et modifiez les utilisateur du staffboard .</p>
          </div>
          )}
        </section>

      </div>
    </div>
  );
};

export default PixetMotionDashboard;
