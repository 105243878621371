import React from 'react';
import axios from 'axios';
import './Modal.scss';

const DeletePlayerModal = ({ teamId, closeModal, removeTeamFromList }) => {
  const handleDelete = async () => {
    try {
      await axios.delete(`https://api.supra-event.fr/api/tournament/teams/${teamId}`);
      removeTeamFromList(teamId);
      closeModal();
    } catch (error) {
      console.error('Erreur lros de la suppresion de l\'équipe', error);
    }
    // Ajouter la logique pour supprimer le joueur ici
  };

  return (
    <div className="modal-deleteteams">
      <div className="modal-content-deleteteams">
        <h2>Supprimer l'équipe</h2>
        <p>Êtes vous sur de vouloir supprimé le players ?</p>
        <div className='button-overlay'>
          <button className="delete-btn" onClick={handleDelete}>Supprimer</button>
          <button className='close-btn' onClick={closeModal}>Fermer</button>
        </div>
      </div>
    </div>
  );
};

export default DeletePlayerModal;
