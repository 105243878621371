import React, { useState } from 'react';
import axios from 'axios';
import './Modal.scss';

const AddMapModal = ({ closeModal, updateMapList }) => {
  const [mapName, setMapName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newMap = { 
        name: mapName
      };
      await axios.post('https://api.supra-event.fr/api/tournament/maps', newMap);
      updateMapList();
      closeModal();
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la carte:', error);
    }
  };

  return (
    <div className="modal-addmaps">
      <div className="modal-content-addmaps">
        <h2>Ajouter une Map</h2>
        <form onSubmit={handleSubmit}>
          <label>Nom de la Map</label>
          <input 
            type="text" 
            value={mapName} 
            onChange={(e) => setMapName(e.target.value)} 
            required 
          />
          <button className='addmaps' type="submit">Ajouter</button>
          <button className='closemaps' type="button" onClick={closeModal}>Fermer</button>
        </form>
      </div>
    </div>
  );
};

export default AddMapModal;
