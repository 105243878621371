import React from 'react';
import axios from 'axios';
import './Modal.scss';

const DeleteMapsModal = ({ mapId, closeModal, removeMapsFromList }) => {
  const handleDelete = async () => {
    try {
      await axios.delete(`https://api.supra-event.fr/api/tournament/maps/${mapId}`);
      removeMapsFromList(mapId);
      closeModal();
    } catch (error) {
      console.error('Erreur lors de la suppression de la carte:', error.message);
    }
  };

  return (
    <div className="modal-deletemaps">
      <div className="modal-content-deletemaps">
        <h2>Supprimer la Map</h2>
        <p>Êtes-vous sûr de vouloir supprimer la map ?</p>
        <div className='button-overlay'>
          <button className="delete-btn" onClick={handleDelete}>Supprimer</button>
          <button className='close-btn' onClick={closeModal}>Fermer</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteMapsModal;
