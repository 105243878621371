import React, { useState } from 'react';
import axios from 'axios';
import './Modal.scss'; // Assurez-vous que ce fichier contient les styles nécessaires pour les modals

const AddUserModal = ({ closeModal, updateUserList }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [urlProfile, setUrlProfile] = useState('');
  const [role, setRole] = useState('');
  const [error, setError] = useState(''); // État pour gérer les erreurs

  const handleSubmit = async (e) => {
    e.preventDefault(); // Empêche le comportement par défaut du formulaire
    setError(''); // Réinitialiser le message d'erreur

    try {
      const newUser = {
        username,
        password,
        url_profile: urlProfile,
        role
      };
      // Envoi de la requête POST pour ajouter un nouvel utilisateur
      await axios.post('https://api.supra-event.fr/api/users', newUser);
      updateUserList(); // Met à jour la liste des utilisateurs après l'ajout
      closeModal(); // Ferme le modal après l'ajout
    } catch (error) {
      console.error('Erreur lors de l\'ajout de l\'utilisateur:', error.response ? error.response.data.message : error.message);
      setError('Une erreur est survenue lors de l\'ajout de l\'utilisateur.'); // Affiche un message d'erreur
    }
  };

  return (
    <div className="modal-addusers">
      <div className="modal-content-addusers">
        <h2>Ajouter un Utilisateur</h2>
        {/* Affiche l'erreur s'il y en a une */}
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <label>Nom d'utilisateur</label>
          <input 
            type="text" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            required 
          />
          <label>Mot de passe</label>
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
          <label>URL du Profil</label>
          <input 
            type="text" 
            value={urlProfile} 
            onChange={(e) => setUrlProfile(e.target.value)} 
            required 
          />
          <label>Rôle</label>
          <select 
            value={role} 
            onChange={(e) => setRole(e.target.value)} 
            required
          >
            <option value="" disabled>Sélectionner un rôle</option>
            <option value="admin">Admin</option>
            <option value="pixetmotion">Pixetmotion</option>
            <option value="staffpix">Staffpix</option>
            <option value="peresourass">peresourass</option>
            {/* Ajoutez d'autres options si nécessaire */}
          </select>
          <button className='add-user' type="submit">Ajouter</button>
          <button className='close-adduser' type="button" onClick={closeModal}>Fermer</button>
        </form>
      </div>
    </div>
  );
};

export default AddUserModal;
