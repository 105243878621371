import React, { useState, useEffect } from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import AddUserModal from './Modal/AddUsersModal'; // Assure-toi que ce fichier existe
import EditUserModal from './Modal/EditUsersModal'; // Assure-toi que ce fichier existe
import DeleteUserModal from './Modal/DeleteUsersModal'; // Assure-toi que ce fichier existe
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import './Users.scss';
import Toolbar from '../../../components/Toolbar/Toolbar';
import images from '../../../assets/staff'; // Importe tes images

const Users = () => {
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(null);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fonction pour récupérer les utilisateurs depuis l'API
  const fetchUsers = async () => {
    try {
      const response = await fetch('https://api.supra-event.fr/api/users');
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des utilisateurs');
      }
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Récupérer les utilisateurs au chargement du composant
  useEffect(() => {
    fetchUsers();
  }, []);

  const openAddUserModal = () => setShowAddUserModal(true);
  const openEditUserModal = (user) => setShowEditUserModal(user);
  const openDeleteUserModal = (userId) => setShowDeleteUserModal(userId);
  const closeModals = () => {
    setShowAddUserModal(false);
    setShowEditUserModal(null);
    setShowDeleteUserModal(null);
  };

  // Met à jour la liste des utilisateurs après l'ajout, la modification ou la suppression
  const updateUserList = async () => {
    await fetchUsers();
    closeModals();
  };

  const updateUser = (updatedUser) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user._id === updatedUser._id ? updatedUser : user
      )
    );
  };

  const removeUserFromList = (userId) => {
    setUsers((prevUsers) =>
      prevUsers.filter((user) => user._id !== userId)
    );
    closeModals();
  };

  if (loading) return <p>Chargement...</p>;
  if (error) return <p>Erreur : {error}</p>;

  return (
    <section className="section-users">
      <Navbar />
      <Toolbar onOpenModal={openAddUserModal} />
      <div className='users-content'>

        <div className="users-list">
          {users.length === 0 ? (
            <p>Aucun utilisateur trouvé.</p>
          ) : (
            <div className="users-cards-container">
              {users.map((user) => (
                <div key={user._id} className="user-card">
                  <img 
                    src={images[user.url_profile]} 
                    alt={`Profil ${user.username}`} 
                    className='profile-user'
                  />
                  <h3>{user.username}</h3>
                  <p><strong>Mot de passe:</strong> {user.password}</p>
                  <p><strong>Rôle:</strong> {user.role}</p>
                  <div className="user-card-actions">
                    <FontAwesomeIcon 
                      icon={faEdit} 
                      onClick={() => openEditUserModal(user)} 
                      className="edit-icon-users" 
                    />
                    <FontAwesomeIcon 
                      icon={faTrash} 
                      onClick={() => openDeleteUserModal(user._id)} 
                      className="delete-icon-users" 
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Modals */}
        {showAddUserModal && <AddUserModal closeModal={closeModals} updateUserList={updateUserList} />}
        {showEditUserModal && (
          <EditUserModal user={showEditUserModal} closeModal={closeModals} updateUser={updateUser} />
        )}
        {showDeleteUserModal && (
          <DeleteUserModal userId={showDeleteUserModal} closeModal={closeModals} removeUserFromList={removeUserFromList} />
        )}
      </div>
    </section>
  );
};

export default Users;
