import React from "react";
import './Modal.scss';

const DeleteSanctionModal = ({ closeModal, sanction, deleteSanction }) => {
    const handleDelete = async () => {
        console.log('ID de la sanction à supprimer :', sanction._id); // Utilise _id
        try {
            const response = await fetch(`https://api.supra-event.fr/api/staffboard/sanctions/${sanction._id}`, {
                method: "DELETE",
            });

            if (response.ok) {
                deleteSanction(sanction._id);  // Supprime la sanction dans la liste principale
                closeModal();  // Ferme le modal
            } else {
                console.error("Erreur lors de la suppression de la sanction");
            }
        } catch (error) {
            console.error("Erreur lors de la suppression:", error);
        }
    };

    return (
        <div className="modal-deletesanctions">
            <div className="modal-content-deletesanctions">
                <h2>Supprimer la sanction</h2>
                <p>Es-tu sûr de vouloir supprimer la sanction pour <strong>{sanction.username}</strong> sur le réseau <strong>{sanction.network}</strong> ?</p>
                <div className="form-actions">
                    <button className="btn-delete" onClick={handleDelete}>supprimer</button>
                    <button className="btn-cancel" onClick={closeModal}>Annuler</button>
                </div>
            </div>
        </div>
    );
};

export default DeleteSanctionModal;
