// /src/assets/images/index.js
import Pix from './pix.png';
import HellFire from './hellfire.png';
import Betrem from './betrem.png';
import Romain from './romain.png';
import Fransceco from './fransceco.png';
import Miss from './miss.png';
import Tassimo from './tassimo.png';
import Louis from './louis.png'
import Default from './Default.png'


const images = {
  pix: Pix,
  hellfire: HellFire,
  betrem: Betrem,
  romain: Romain,
  fransceco: Fransceco,
  miss: Miss,
  tassimo: Tassimo,
  louis: Louis,
  default: Default,

};

export default images;
