import React from 'react';
import axios from 'axios';
import './Modal.scss'; // Assure-toi d'importer les styles pour le modal

const DeleteStudioModal = ({ studioId, closeModal, updateStudios }) => {
  // URL de l'API pour supprimer le studio
  const apiUrl = `https://api.supra-event.fr/api/pixionautes/studio/${studioId}`;

  const handleDelete = async () => {
    try {
      // Envoie une requête DELETE au serveur
      await axios.delete(apiUrl);
      console.log('Studio supprimé avec succès');

      // Mise à jour des studios après la suppression
      if (typeof updateStudios === 'function') {
        updateStudios(prevStudios => prevStudios.filter(studio => studio._id !== studioId)); // Utilise _id pour la suppression
      } else {
        console.error('updateStudios n\'est pas une fonction');
      }
      closeModal(); // Ferme le modal après la suppression
    } catch (error) {
      console.error('Erreur lors de la suppression du studio:', error);
    }
  };

  return (
    <div className="modal-deletestudios">
      <div className="modal-content-deletestudios">
        <h2>Confirmer la suppression</h2>
        <p>Êtes-vous sûr de vouloir supprimer ce studio ?</p>
        <div className="button-overlay">
          <button onClick={handleDelete} className="delete-btn">Supprimer</button>
          <button onClick={closeModal} className="close-btn">Annuler</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteStudioModal;
