import React from 'react';
import './TextWithGrain.scss'; // Assurez-vous d'importer votre fichier SCSS

const TextWithGrain = ({ text, grainLevel, transform, fontSize }) => {
    const grainStyle = {
        opacity: grainLevel / 100, // Assurez-vous que grainLevel est entre 0 et 100
    };

    return (
        <div className="login-text" style={{ transform }}>
            <h2 style={{ ...grainStyle, fontSize }}>
                {text}
            </h2>
        </div>
    );
};

export default TextWithGrain;
