import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthContext } from './context/AuthContext/AuthContext';


// DEFAULT
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import LoginPage from './pages/LoginPage/LoginPage';

// HELLFIRE ADMIN
import AdminDashboard from './pages/AdminDashboard/Home/AdminDashboard';
import Users from './pages/AdminDashboard/Users/Users';


// PIX TOURNAMENT 
import Players from './pages/PixEtMotion/PixTournament/Players/Players';
import Teams from './pages/PixEtMotion/PixTournament/Teams/Teams';
import Matchs from './pages/PixEtMotion/PixTournament/Matchs/Matchs'
import Maps from './pages/PixEtMotion/PixTournament/Maps/Maps';
import Brackets from './pages/PixEtMotion/PixTournament/Brackets/Brackets';

// PIXIONAUTES
import CardGames from './pages/PixEtMotion/Pixionautes/CardGame/card_games';
import StudioGraphique from './pages/PixEtMotion/Pixionautes/Studio-Graphique/studio';
import Staff from './pages/PixEtMotion/Pixionautes/Staff/Staff';
import Partners from './pages/PixEtMotion/Pixionautes/Partner/Partner';


// STAFFBOARD
import News from './pages/PixEtMotion/StaffBoard/News/News';
import Ressources from './pages/PixEtMotion/StaffBoard/Ressources/Ressources';
import Sanction from './pages/PixEtMotion/StaffBoard/Sanction/Sanction';
import LiveControl from './pages/PixEtMotion/StaffBoard/LiveControl/Overlay';
import PixBot from './pages/PixEtMotion/StaffBoard/PixBot/PixBot';


const App = () => {
  const { user } = useContext(AuthContext);

  return (
    <Router>
      <Routes>
        {/* ROUTES PAR DEFAUT */}
        <Route path="/" element={<LoginPage />} />

        {/* ROUTES STAFFBOARD */}
        <Route path="/staffboard/home" element={<PrivateRoute component={AdminDashboard} authorizedRoles={['admin', 'pixetmotion', 'staffpix', 'peresourass']} userRole={user ? user.role : null}/> }/>
        <Route path='/staffboard/users' element={<PrivateRoute component={Users} authorizedRoles={['admin', 'pixetmotion']} userRole={user ? user.role : null} />} />

        {/* SUBMENU PIX TOURNAMENT */}
        <Route path="/staffboard/pixtournament/players" element={<PrivateRoute component={Players} authorizedRoles={['pixetmotion', 'admin', 'staffpix']} userRole={user ? user.role : null} /> }/>
        <Route path="/staffboard/pixtournament/teams" element={<PrivateRoute component={Teams} authorizedRoles={['pixetmotion', 'admin', 'staffpix']} userRole={user ? user.role : null} /> }/>
        <Route path="/staffboard/pixtournament/match" element={<PrivateRoute component={Matchs} authorizedRoles={['pixetmotion', 'admin', 'staffpix']} userRole={user ? user.role : null} /> }/>
        <Route path="/staffboard/pixtournament/maps" element={<PrivateRoute component={Maps} authorizedRoles={['pixetmotion', 'admin', 'staffpix']} userRole={user ? user.role : null} /> }/>
        <Route path="/staffboard/pixtournament/brackets" element={<PrivateRoute component={Brackets} authorizedRoles={['pixetmotion', 'admin', 'staffpix']} userRole={user ? user.role : null}/> }/>


      {/* SUBMENU PIXIONAUTES */}
      <Route path='/staffboard/pixionautes/cardgames' element={<PrivateRoute component={CardGames} authorizedRoles={['pixetmotion', 'admin', 'staffpix']} userRole={user ? user.role : null} />} />
      <Route path='/staffboard/pixionautes/studiographique' element={<PrivateRoute component={StudioGraphique} authorizedRoles={['pixetmotion', 'admin', 'staffpix']} userRole={user ? user.role : null} />} />
      <Route path='/staffboard/pixionautes/staff' element={<PrivateRoute component={Staff} authorizedRoles={['pixetmotion', 'staffpix', 'admin']} userRole={user ? user.role : null} />} />
      <Route path='/staffboard/pixionautes/partner' element={<PrivateRoute component={Partners} authorizedRoles={['pixetmotion', 'staffpix', 'admin']} userRole={user ? user.role : null} />} />


      {/* SUBMENU STAFFBOARD */}
      <Route path='/staffboard/livecontrol/news' element={<PrivateRoute component={News} authorizedRoles={['pixetmotion', 'admin', 'staffpix', 'peresourass']} userRole={user ? user.role : null} />} />
      <Route path='/staffboard/livecontrol/ressources' element={<PrivateRoute component={Ressources} authorizedRoles={['pixetmotion', 'admin', 'staffpix']} userRole={user ? user.role : null} />}/>
      <Route path='/staffboard/livecontrol/sanction' element={<PrivateRoute component={Sanction} authorizedRoles={['pixetmotion', 'admin', 'staffpix']} userRole={user ? user.role : null}/>}/>
      <Route path='/staffboard/livecontrol/overlay' element={<PrivateRoute component={LiveControl} authorizedRoles={['pixetmotion', 'admin', 'staffpix']} userRole={user ? user.role : null}/>}/>
      <Route path='/staffboard/livecontrol/pixbot' element={<PrivateRoute component={PixBot} authorizedRoles={['pixetmotion', 'admin', 'staffpix']} userRole={user ? user.role : null}/>}/>

        <Route path="/unauthorized" element={<h2>Accès refusé</h2>} />
      </Routes>
    </Router>
  );
};

export default App;
