import React, { useEffect, useState } from "react";
import './Modal.scss';

const EditLiveControlModal = ({ closeModal, livecontrol, updateLiveControl }) => {
    const [formData, setFormData] = useState({
        title: '',
        redirection: '',
        description: '',

    });

    useEffect(() => {
        if (livecontrol) {
            setFormData({
                title: livecontrol.title || '',
                redirection: livecontrol.redirection || '',
                description: livecontrol.description || '',
            });
        }
    }, [livecontrol]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value 
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://api.supra-event.fr/api/staffboard/livecontrol/${livecontrol._id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                updateLiveControl({ ...livecontrol, ...formData });
                closeModal();
            } else {
                console.error("Erreur lors de la mise à jour du livecontrol");
            } 

        } catch (error) {
            console.log("Erreur lors de la mise à jour", error)
        }
    };

    return (
        <div className="modal-editlivecontrols">
            <div className="modal-content-editlivecontrols">
                <h2>Modifer le LiveControl</h2>
                <form onSubmit={handleSubmit}>
                    <label>
                        Titre:
                        <input type="text" name="title" value={formData.title} onChange={handleChange} required/>
                    </label>
                    <label>
                        Redirection :
                        <input type="text" name="redirection" value={formData.redirection} onChange={handleChange} required/>
                    </label>
                    <label>
                        Description :
                        <textarea type="text" name="description" value={formData.description} onChange={handleChange} required/>
                    </label>

                    <div className="form-actions">
                        <button className="submit-editlivecontrols" type="submit" >Sauvergarder</button>
                        <button className="close-editlivecontrols" type="button" onClick={closeModal}>Annuler</button>
                    </div>
                </form>
            </div>

        </div>
    )
}

export default EditLiveControlModal;