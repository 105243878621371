import React, { useState, useEffect } from 'react';
import './Modal.scss';

const EditCardModal = ({ card, closeModal, updateCard }) => {
  const [updatedCard, setUpdatedCard] = useState(card);

  useEffect(() => {
    setUpdatedCard(card); // Met à jour l'état lorsque la carte change
  }, [card]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateCard(updatedCard); // Appelle la fonction updateCard pour mettre à jour la carte
      closeModal(); // Ferme le modal après l'édition
    } catch (error) {
      console.error('Erreur lors de l\'édition de la carte:', error);
    }
  };

  return (
    <div className="modal-editcards">
      <div className="modal-content-editcards">
        <h2>Éditer la carte</h2>
        <form onSubmit={handleSubmit}>
          <label>Titre</label>
          <input
            type="text"
            value={updatedCard.title || ''} // Affiche la valeur actuelle du titre
            onChange={(e) => setUpdatedCard({ ...updatedCard, title: e.target.value })}
          />

          <label>Description</label>
          <textarea
            type="text"
            value={updatedCard.description || ''} // Affiche la valeur actuelle de la description
            onChange={(e) => setUpdatedCard({ ...updatedCard, description: e.target.value })}
          />

          <label>Rareté</label>
          <select
            value={updatedCard.rarity || ''} // Affiche la valeur actuelle de la rareté
            onChange={(e) => setUpdatedCard({ ...updatedCard, rarity: e.target.value })}
          >
            <option value="">Sélectionner la rareté</option>
            <option value="common">Commune</option>
            <option value="rare">Rare</option>
            <option value="mythique">Mythique</option>
            <option value="ultime">Ultime</option>
          </select>

          <label>URL de l'image</label>
          <input
            type="text"
            value={updatedCard.image_url || ''} // Affiche l'URL actuelle de l'image
            onChange={(e) => setUpdatedCard({ ...updatedCard, image_url: e.target.value })}
          />

          <button type="submit" className="edit-card">Enregistrer</button>
          <button type="button" onClick={closeModal} className="close-editcard">Annuler</button>
        </form>
      </div>
    </div>
  );
};

export default EditCardModal;
