import React, { useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext/AuthContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown, faBullseye, faPeopleGroup, faHome } from "@fortawesome/free-solid-svg-icons";
import './Navbar.scss';
import images from '../../assets/staff'; // Importe tes images
import TextWithGrain from '../../components/TextWithGrain/TextWithGrain';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';

const Navbar = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [menuState, setMenuState] = useState({
    staffboard: true,
    home: false,
    pixtournament: false,
    pixionautes: false,
    livecontrol: false,
  });

  const toggleMenu = (menu) => {
    setMenuState((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu],
    }));
  };

  const handleLogout = async () => {
    try {
      await logout(); // Assurez-vous que logout est bien une fonction async
      navigate('/');  // Redirection après la déconnexion
    } catch (err) {
      console.error("Erreur lors de la déconnexion : ", err);
      // Affichez une notification ou un message d'erreur si nécessaire
    }
  };

  const SubMenu = ({ title, children, isOpen, toggle, isActive }) => (
    <li className={`submenu ${isActive ? 'active' : ''}`}>
      <div onClick={toggle}>
        {title} {isOpen ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}
      </div>
      {isOpen && <ul className="submenu-items">{children}</ul>}
    </li>
  );

  // Check which menu should be open based on the current URL
  React.useEffect(() => {
    const path = location.pathname;

    setMenuState((prevState) => ({
      ...prevState,
      home: path.startsWith('/staffboard/home'),
      pixtournament: path.startsWith('/staffboard/pixtournament'),
      pixionautes: path.startsWith('/staffboard/pixionautes'),
      livecontrol: path.startsWith('/staffboard/livecontrol'),
      staffboard: true, // Toujours ouvert
    }));
  }, [location]);

  return (
    <section>

  
    <div className="navbar">
      <div className="navbar-header">
        <div className='navbar-title'>
          <TextWithGrain text="StaffBoard" grainLevel={100} fontSize={30} />
        </div>
        {user && user.url_profile ? (
          <img src={images[user.url_profile]} alt="Profile" className="profile-image" />
        ) : (
          <div className="profile-image placeholder">No Image</div>
        )}
        <h2 className="navbar-role">{user ? user.username : 'Guest'}</h2>
      </div>

      <div className="navbar-menu">

        {/* Section Pix&Motion visible pour admin et pixetmotion */}
        {(user?.role === 'admin' || user?.role === 'staffpix') && (
          <div className={`menu-section ${menuState.staffboard ? 'active' : ''}`}>
            <div className="menu-title" onClick={() => toggleMenu('staffboard')}>
              StaffBoard {menuState.staffboard ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}
            </div>
            {menuState.staffboard && (
              <ul className="menu-items">
                {/* Sous-Menu Home */}
                {(user?.role === 'admin' || user?.role === 'staffpix') && (
                  <SubMenu title="• Home" isOpen={menuState.home} toggle={() => toggleMenu('home')} isActive={menuState.home}>
                    <li><Link to="/staffboard/home" className={location.pathname === '/staffboard/home' ? 'active-link' : ''}>Accueil</Link></li>


                    {(user?.role === 'admin' || user?.role === 'pixetmotion') && (
                      <li><Link to="/staffboard/users" className={location.pathname === '/staffboard/users' ? 'active-link' : ''}>Users</Link></li>
                    )}
                    </SubMenu>
                  )}

                {/* Sous-menu Pixtournament visible pour admin, pixetmotion, et staffpix */}
                {(user?.role === 'admin' || user?.role === 'staffpix') && (
                  <SubMenu title="• PIXTOURNAMENT" isOpen={menuState.pixtournament} toggle={() => toggleMenu('pixtournament')} isActive={menuState.pixtournament}>
                    <li><Link to="/staffboard/pixtournament/players" className={location.pathname === '/staffboard/pixtournament/players' ? 'active-link' : ''}>Players</Link></li>
                    <li><Link to="/staffboard/pixtournament/teams" className={location.pathname === '/staffboard/pixtournament/teams' ? 'active-link' : ''}>Teams</Link></li>
                    <li><Link to="/staffboard/pixtournament/match" className={location.pathname === '/staffboard/pixtournament/match' ? 'active-link' : ''}>Match</Link></li>
                    <li><Link to="/staffboard/pixtournament/brackets" className={location.pathname === '/staffboard/pixtournament/brackets' ? 'active-link' : ''}>Brackets</Link> </li>
                    <li><Link to="/staffboard/pixtournament/maps" className={location.pathname === '/staffboard/pixtournament/maps' ? 'active-link' : ''}>Maps</Link></li>
                  </SubMenu>
                )}

                {/* Sous-menu Pixionautes visible pour admin et staffpix */}
                {(user?.role === 'admin' || user?.role === 'staffpix') && (
                  <SubMenu title="• PIXIONAUTES" isOpen={menuState.pixionautes} toggle={() => toggleMenu('pixionautes')} isActive={menuState.pixionautes}>
                    <li><Link to="/staffboard/pixionautes/cardgames" className={location.pathname === '/staffboard/pixionautes/cardgames' ? 'active-link' : ''}>Card Games</Link></li>
                    <li><Link to="/staffboard/pixionautes/studiographique" className={location.pathname === '/staffboard/pixionautes/studiographique' ? 'active-link' : ''}>Studio-Graphique</Link></li>
                    <li><Link to="/staffboard/pixionautes/staff" className={location.pathname === '/staffboard/pixionautes/staff' ? 'active-link' : ''}>Staff</Link></li>
                    <li><Link to="/staffboard/pixionautes/partner" className={location.pathname === '/staffboard/pixionautes/partner' ? 'active-link' : ''}>Partenaire</Link></li>
                  </SubMenu>
                )}

                {/* Sous-menu Live Control visible pour admin uniquement */}
                {(user?.role === 'admin' || user?.role === 'staffpix') && (
                  <SubMenu title="• Live Control" isOpen={menuState.livecontrol} toggle={() => toggleMenu('livecontrol')} isActive={menuState.livecontrol}>
                    <li><Link to="/staffboard/livecontrol/news" className={location.pathname === '/staffboard/livecontrol/news' ? 'active-link' : ''}>News</Link></li>
                    <li><Link to="/staffboard/livecontrol/ressources" className={location.pathname === '/staffboard/livecontrol/ressources' ? 'active-link' : ''}>Ressources</Link></li>
                    <li><Link to="/staffboard/livecontrol/sanction" className={location.pathname === '/staffboard/livecontrol/sanction' ? 'active-link' : ''}>Sanction</Link></li>
                    <li><Link to="/staffboard/livecontrol/overlay" className={location.pathname === '/staffboard/livecontrol/overlay' ? 'active-link' : ''}>Overlay</Link></li>
                    <li><Link to="/staffboard/livecontrol/pixbot" className={location.pathname === '/staffboard/livecontrol/pixbot' ? 'active-link' : ''}>Pix'Bot</Link></li>

                  </SubMenu>
                )}
              </ul>
            )}
          </div>
        )}
      </div>

      <div className="navbar-footer">
        {!user ? (
          <Link to="/">Login</Link>
        ) : (
          <button onClick={handleLogout}>Déconnexion</button>
        )}
      </div>
    </div>

      {/* Navbar Phone */}
      <div className='navbar-phone'>
        <ul>
          <li><Link to="/staffboard/home"><FontAwesomeIcon icon={faHome} /></Link></li>
          <li><Link to="/staffboard/pixionautes/cardgames"><FontAwesomeIcon icon={faPeopleGroup} /></Link></li>
          <li><Link to="/staffboard/livecontrol/news"><FontAwesomeIcon icon={faTwitch} /></Link></li>
          <li><Link to="/staffboard/pixtournament/players"><FontAwesomeIcon icon={faBullseye} /></Link></li>
        </ul>
      </div>

    </section>
  );
};

export default Navbar;
