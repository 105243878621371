import React, { useState } from "react";
import './Modal.scss';

const AddSanctionModal = ({ closeModal, addSanction }) => {
    const [form, setForm] = useState({ network: "", username: "", moderator: "", type: "", reason: "" });

    const handleChange = e => setForm({ ...form, [e.target.name]: e.target.value });

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = await fetch("https://api.supra-event.fr/api/staffboard/sanctions", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(form)
            });
            if (response.ok) {
                addSanction();
                closeModal();
            } else {
                console.error("Erreur lors de l'ajout de la sanction");
            }
        } catch (error) {
            console.error("Erreur lors de l'ajout de la sanction:", error);
        }
    };

    return (
        <div className="modal-addsanctions">
            <div className="modal-content-addsanctions">
                <h2>Ajouter une sanction</h2>
                <form onSubmit={handleSubmit}>
                    <label>Réseau :
                        <select name="network" value={form.network} onChange={handleChange}>
                            <option value="">Sélectionner un réseau</option>
                            <option value="Twitch">Twitch</option>
                            <option value="Discord">Discord</option>
                            <option value="TikTok">TikTok</option>
                        </select>
                    </label>
                    <label>Username :
                        <input type="text" name="username" value={form.username} onChange={handleChange} required />
                    </label>
                    <label>Modérateur :
                        <input type="text" name="moderator" value={form.moderator} onChange={handleChange} required />
                    </label>
                    <label>Type de sanction :
                        <input type="text" name="sanctionType" value={form.sanctionType} onChange={handleChange} required />
                    </label>
                    <label>Raison :
                        <input type="text" name="reason" value={form.reason} onChange={handleChange} required />
                    </label>
                    <div className="form-actions">
                        <button className="add-sanction" type="submit">Ajouter</button>
                        <button className="close-sanction" type="button" onClick={closeModal}>Annuler</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddSanctionModal;
