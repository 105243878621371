import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../../../../components/Navbar/Navbar';
import AddCardModal from './Modals/AddCardModal';
import EditCardModal from './Modals/EditCardModal';
import DeleteCardModal from './Modals/DeleteCardModal';
import Toolbar from '../../../../components/Toolbar/Toolbar';
import './card_games.scss';

const CardGames = () => {
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [showEditCardModal, setShowEditCardModal] = useState(null);
  const [showDeleteCardModal, setShowDeleteCardModal] = useState(null);
  const [cards, setCards] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');  // État pour la recherche

  // URL de base pour les appels API
  const apiUrl = 'https://api.supra-event.fr/api/pixionautes/cardgames';

  // Charger les cartes depuis l'API
  const fetchCards = async () => {
    try {
      const response = await axios.get(apiUrl);
      setCards(response.data);
    } catch (error) {
      console.error('Erreur lors du chargement des cartes:', error);
    }
  };

  // Appeler fetchCards lors du premier rendu
  useEffect(() => {
    fetchCards();
  }, []);

  // Éditer une carte existante
  const editCard = async (card) => {
    try {
      const response = await axios.put(`${apiUrl}/${card._id}`, card);
      setCards(cards.map(c => (c._id === card._id ? response.data : c)));
    } catch (error) {
      console.error('Erreur lors de l\'édition de la carte:', error);
    }
  };

  // Ouvrir/fermer modals
  const openAddCardModal = () => setShowAddCardModal(true);
  const openEditCardModal = (card) => setShowEditCardModal(card);
  const openDeleteCardModal = (cardId) => setShowDeleteCardModal(cardId);
  const closeModals = () => {
    setShowAddCardModal(false);
    setShowEditCardModal(null);
    setShowDeleteCardModal(null);
  };

  // Filtrer les cartes en fonction du terme de recherche
  const filteredCards = cards.filter(card =>
    card.title.toLowerCase().includes(searchTerm.toLowerCase()) // Filtrer selon le terme de recherche
  );

  return (
    <section className="section-cardgames">
      <Navbar />
      {/* Passer searchTerm et setSearchTerm à la Toolbar */}
      <Toolbar onOpenModal={openAddCardModal} onSearch={setSearchTerm}  // Utiliser setSearchTerm pour mettre à jour le terme de recherche
      />
      <div className="card-container">
        {filteredCards.length > 0 ? (
          filteredCards.map((card, index) => (
            <div key={index} className="card">
              <h2 className="card-title">{card.title}</h2>
              <img src={card.image_url} alt={card.title} className="card-image" />
              <p className="card-description">{card.description}</p>

              {/* Boutons pour éditer et supprimer */}
              <div className="card-actions">
                <button onClick={() => openEditCardModal(card)} className="edit-btn">Éditer</button>
                <button onClick={() => openDeleteCardModal(card._id)} className="delete-btn">Supprimer</button>
              </div>
            </div>
          ))
        ) : (
          <p>Aucune carte trouvée.</p>  // Message si aucune carte ne correspond à la recherche
        )}
      </div>

      {/* Modals */}
      {showAddCardModal && <AddCardModal closeModal={closeModals} updateCards={setCards} />}
      {showEditCardModal && (<EditCardModal card={showEditCardModal} closeModal={closeModals} updateCard={editCard}/>)}
      {showDeleteCardModal && (<DeleteCardModal cardId={showDeleteCardModal} closeModal={closeModals} updateCards={setCards}/>)}
    </section>
  );
};

export default CardGames;
