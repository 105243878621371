import React, { useState } from 'react';
import axios from 'axios';
import './Modal.scss'; // Assure-toi d'importer les styles

const AddPartnerModal = ({ closeModal, updatePartners }) => {
  const [newPartner, setNewPartner] = useState({
    title: '',
    description: '',
    image_url: ''
  });

  const apiUrl = 'https://api.supra-event.fr/api/pixionautes/partner';

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Envoie des données au serveur
      const response = await axios.post(apiUrl, newPartner);
      console.log('Partenaire ajouté avec succès:', response.data);

      // Mise à jour des partenaires avec la réponse du serveur
      if (response.data && typeof updatePartners === 'function') {
        updatePartners(prevPartners => [...prevPartners, response.data]);
      } else {
        console.error('updatePartners n\'est pas une fonction ou la réponse est invalide');
      }
      closeModal(); // Ferme le modal après l'ajout
    } catch (error) {
      console.error('Erreur lors de l\'ajout du partenaire:', error);
    }
  };

  return (
    <div className="modal-addpartners">
      <div className="modal-content-addpartners">
        <h2>Ajouter un partenaire</h2>
        <form onSubmit={handleSubmit}>
          <label>Titre</label>
          <input
            type="text"
            placeholder="Titre"
            value={newPartner.title}
            onChange={(e) => setNewPartner({ ...newPartner, title: e.target.value })}
          />

          <label>Description</label>
          <textarea
            type="text"
            placeholder="Description"
            value={newPartner.description}
            onChange={(e) => setNewPartner({ ...newPartner, description: e.target.value })}
          />

          <label>URL de l'image</label>
          <input
            type="text"
            placeholder="URL de l'image"
            value={newPartner.image_url}
            onChange={(e) => setNewPartner({ ...newPartner, image_url: e.target.value })}
          />

          <button type="submit" className="addpartner">Ajouter</button>
          <button type="button" onClick={closeModal} className="closepartner">Annuler</button>
        </form>
      </div>
    </div>
  );
};

export default AddPartnerModal;
