import React, { useState, useEffect } from 'react';
import Navbar from '../../../../components/Navbar/Navbar';
import AddResourceModal from './Modal/AddRessourcesModal';
import EditResourceModal from './Modal/EditRessourcesModal';
import DeleteResourceModal from './Modal/DeleteRessourcesModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import './Ressources.scss';
import Toolbar from '../../../../components/Toolbar/Toolbar';

const Ressources = () => {
  const [showAddResourceModal, setShowAddResourceModal] = useState(false);
  const [showEditResourceModal, setShowEditResourceModal] = useState(null);
  const [showDeleteResourceModal, setShowDeleteResourceModal] = useState(null);
  const [resourcesList, setResourcesList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // État pour le terme de recherche

  // Fonction pour récupérer les ressources depuis l'API
  const fetchResources = async () => {
    try {
      const response = await fetch('https://api.supra-event.fr/api/staffboard/ressources');
      const data = await response.json();
      setResourcesList(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des ressources', error);
    }
  };

  // Effectuer la récupération des ressources au chargement du composant
  useEffect(() => {
    fetchResources();
  }, []);

  const openAddResourceModal = () => setShowAddResourceModal(true);
  const openEditResourceModal = (resource) => {
    console.log("Ouverture du modal d'édition avec la ressource :", resource); // Vérifie les données ici
    setShowEditResourceModal(resource);
  };

  const openDeleteResourceModal = (resourceId, commandId) => {
    setShowDeleteResourceModal({ resourceId, commandId });
  };

  const closeModals = () => {
    setShowAddResourceModal(false);
    setShowEditResourceModal(null);
    setShowDeleteResourceModal(null);
  };

  // Actualiser la liste des ressources
  const refreshResourcesList = async () => {
    await fetchResources();
    closeModals();
  };

  const updateResource = (updatedResource) => {
    setResourcesList((prevResourcesList) =>
      prevResourcesList.map((resource) =>
        resource._id === updatedResource._id ? updatedResource : resource
      )
    );
  };

  const removeCommandFromList = async (resourceId, commandId) => {
    try {
      const response = await fetch(`https://api.supra-event.fr/api/staffboard/ressources/${resourceId}/commands/${commandId}`, {
        method: 'DELETE',
      });
      if (!response.ok) throw new Error('Erreur lors de la suppression de la commande');
      await refreshResourcesList();
    } catch (error) {
      console.error('Erreur lors de la suppression de la commande', error);
    }
  };

  // Filtrer les services existants et les services non affichés
  const existingServices = resourcesList.flatMap(resource =>
    resource.Commandes.map(command => command.service)
  );

  const servicesToDisplay = ["Wizebot", "Twitch", "Sammi", "Lumia", "Autres"].filter(service =>
    !existingServices.includes(service)
  );

  // Filtrer les ressources en fonction du terme de recherche
  const filteredResources = resourcesList.flatMap(resource =>
    resource.Commandes.map(command => ({
      resource,
      command
    }))
  ).filter(item =>
    item.command.service.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <section className="section-ressources">
      <Navbar />
      <Toolbar onOpenModal={openAddResourceModal} onSearch={setSearchTerm} /> {/* Passer setSearchTerm pour mettre à jour le terme de recherche */}
      <div className='ressources-content'>
        <div className="mode-toggle">
          <button className='btn-control-edit' onClick={() => { setEditMode(!editMode); setDeleteMode(false); }}>
            <FontAwesomeIcon icon={faEdit} /> {editMode ? 'Mode Lecture' : 'Mode Édition'}
          </button>
          <button className='btn-control-supp' onClick={() => { setDeleteMode(!deleteMode); setEditMode(false); }}>
            <FontAwesomeIcon icon={faTrash} /> {deleteMode ? 'Mode Lecture' : 'Mode Suppression'}
          </button>
        </div>

        <div className="ressources-grid">
          {filteredResources.length > 0 ? (
            filteredResources.map(({ resource, command }, index) => (
              <div className="ressource-card" key={index}>
                <h3>{command.service}</h3>
                <p>{command.commands.map((cmd, idx) => (
                  <div key={idx}>
                    <strong>{cmd.command} : </strong>{cmd.description}
                    {editMode && (
                      <button className='edit-btn' onClick={() => openEditResourceModal({ ...resource, selectedCommandId: cmd._id })}>
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    )}
                    {deleteMode && (
                      <button className="delete-btn" onClick={() => openDeleteResourceModal(resource._id, cmd._id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    )}
                  </div>
                ))}</p>
              </div>
            ))
          ) : (
            <p>Aucune ressource trouvée.</p>
          )}

          {servicesToDisplay.map((service, index) => (
            <div className="ressource-card" key={index}>
              <h3>{service}</h3>
              <p>Aucune commande disponible pour ce service.</p>
              <div className="ressource-actions">
                <button className='edit-btn' onClick={openAddResourceModal}>Ajouter Commandes</button>
              </div>
            </div>
          ))}
        </div>

        {/* Modals */}
        {showAddResourceModal && <AddResourceModal closeModal={closeModals} updateResourcesList={refreshResourcesList} />}
        {showEditResourceModal && (
          <EditResourceModal 
            resource={showEditResourceModal} 
            closeModal={closeModals} 
            updateResource={updateResource} 
          />
        )}
        {showDeleteResourceModal && (
          <DeleteResourceModal 
            resourceId={showDeleteResourceModal.resourceId} 
            commandId={showDeleteResourceModal.commandId} 
            closeModal={closeModals} 
            removeCommandFromList={removeCommandFromList} 
          />
        )}
      </div>
    </section>
  );
};

export default Ressources;
