import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext/AuthContext';
import './LoginPage.scss';
import TextWithGrain from '../../components/TextWithGrain/TextWithGrain';

const LoginPage = () => {
  const { login, error, user } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !password) {
      // Erreur si les champs sont vides
      return;
    }
    
    // Connexion et récupération de l'utilisateur
    const loggedInUser = await login(username, password);
    
    if (loggedInUser) {
      // Redirection en fonction du rôle
      if (loggedInUser.role === 'admin') {
        navigate('/staffboard/home');
      } else if (loggedInUser.role === 'staffpix') {
        navigate('/staffboard/home');
      } else if (loggedInUser.role === 'peresourass') {
        navigate('/staffboard/livecontrol/news')
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };
    
  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="login-content">
          <div className="login-text">
            <div className='text-grain'>
            <TextWithGrain text="StaffBoard" grainLevel={100} />
            <p className='login-slogan'>Ensemble, faisons de chaque stream un succès !</p>
            </div>
            <div className='text-grain-phone'>
            <TextWithGrain text="StaffBoard" grainLevel={100} fontSize={40}  />
            <p className='login-slogan'>Ensemble, faisons de chaque stream un succès !</p>
            </div>
          </div>
          {error && <p className="error-label">{error}</p>}
          <form className="login-form" onSubmit={handleSubmit}>
            <div>
              <input
                placeholder="Nom d'utilisateur"
                className="input-box"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div>
              <input
                placeholder="Mot de Passe"
                className="input-box"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <button className="input-button" type="submit">Connexion</button>
          </form>
        </div>
        <div className="login-image">
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
