import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../../../../components/Navbar/Navbar';
import AddPartnerModal from './Modals/AddPartnerModal';
import EditPartnerModal from './Modals/EditPartnerModal';
import DeletePartnerModal from './Modals/DeletePartnerModal';
import './Partner.scss';
import Toolbar from '../../../../components/Toolbar/Toolbar';

const Partners = () => {
  const [showAddPartnerModal, setShowAddPartnerModal] = useState(false);
  const [showEditPartnerModal, setShowEditPartnerModal] = useState(null);
  const [showDeletePartnerModal, setShowDeletePartnerModal] = useState(null);
  const [partners, setPartners] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // URL de base pour les appels API
  const apiUrl = 'https://api.supra-event.fr/api/pixionautes/partner';

  // Charger les partenaires depuis l'API
  const fetchPartners = async () => {
    try {
      const response = await axios.get(apiUrl);
      setPartners(response.data);
    } catch (error) {
      console.error('Erreur lors du chargement des partenaires:', error);
    }
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  // Éditer un partenaire existant
  const editPartner = async (partner) => {
    try {
      const response = await axios.put(`${apiUrl}/${partner._id}`, partner);
      setPartners(partners.map(p => (p._id === partner._id ? response.data : p)));
    } catch (error) {
      console.error('Erreur lors de l\'édition du partenaire:', error);
    }
  };

  // Ouvrir/fermer modals
  const openAddPartnerModal = () => setShowAddPartnerModal(true);
  const openEditPartnerModal = (partner) => setShowEditPartnerModal(partner);
  const openDeletePartnerModal = (partnerId) => setShowDeletePartnerModal(partnerId);
  const closeModals = () => {
    setShowAddPartnerModal(false);
    setShowEditPartnerModal(null);
    setShowDeletePartnerModal(null);
  };

  // Filtrer les partenaires en fonction du terme de recherche
  const filteredPartners = partners.filter(partner =>
    (partner.title || '').toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <section className="section-partners">
      <Navbar />
      <Toolbar onOpenModal={openAddPartnerModal} onSearch={setSearchTerm} />
      <div className="partner-container">
        {filteredPartners.length > 0 ? (
          filteredPartners.map((partner, index) => (
            <div key={index} className="partner">
              <h2 className="partner-title">{partner.title}</h2>
              <img src={partner.image_url} alt={partner.title} className="partner-image" />
              <p className="partner-description">{partner.description}</p>

              <div className="partner-actions">
                <button onClick={() => openEditPartnerModal(partner)} className="edit-btn">Éditer</button>
                <button onClick={() => openDeletePartnerModal(partner._id)} className="delete-btn">Supprimer</button>
              </div>
            </div>
          ))
        ) : (
          <p>Aucun partenaire trouvé.</p>
        )}
      </div>

      {showAddPartnerModal && <AddPartnerModal closeModal={closeModals} updatePartners={setPartners} />}
      {showEditPartnerModal && (
        <EditPartnerModal
          partner={showEditPartnerModal}
          closeModal={closeModals}
          editPartner={editPartner}
        />
      )}
      {showDeletePartnerModal && (
        <DeletePartnerModal
          partnerId={showDeletePartnerModal}
          closeModal={closeModals}
          updatePartners={setPartners}
        />
      )}
    </section>
  );
};

export default Partners;
