import React, { useState, useEffect } from 'react';
import Navbar from '../../../../components/Navbar/Navbar';
import AddNewsModal from './Modals/AddNewsModal';
import EditNewsModal from './Modals/EditNewsModal';
import DeleteNewsModal from './Modals/DeleteNewsModal';
import './News.scss';
import Toolbar from '../../../../components/Toolbar/Toolbar';

const News = () => {
  const [showAddNewsModal, setShowAddNewsModal] = useState(false);
  const [showEditNewsModal, setShowEditNewsModal] = useState(null);
  const [showDeleteNewsModal, setShowDeleteNewsModal] = useState(null);
  const [newsList, setNewsList] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // État pour le terme de recherche

  // Fonction pour récupérer les articles de news depuis l'API
  const fetchNews = async () => {
    try {
      const response = await fetch('https://api.supra-event.fr/api/staffboard/news');
      const data = await response.json();
      setNewsList(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des articles de news', error);
    }
  };

  // Effectuer la récupération des articles de news au chargement du composant
  useEffect(() => {
    fetchNews();
  }, []);

  const openAddNewsModal = () => setShowAddNewsModal(true);
  const openEditNewsModal = (news) => setShowEditNewsModal(news);
  const openDeleteNewsModal = (newsId) => setShowDeleteNewsModal(newsId);
  const closeModals = () => {
    setShowAddNewsModal(false);
    setShowEditNewsModal(null);
    setShowDeleteNewsModal(null);
  };

  // Met à jour la liste des articles après l'ajout d'un nouvel article
  const updateNewsList = async () => {
    await fetchNews();
    closeModals();
  };

  const updateNews = (updatedNews) => {
    setNewsList((prevNewsList) =>
      prevNewsList.map((news) =>
        news._id === updatedNews._id ? updatedNews : news
      )
    );
  };

  const removeNewsFromList = (newsId) => {
    setNewsList((prevNewsList) =>
      prevNewsList.filter((news) => news._id !== newsId)
    );
    closeModals();
  };

  // Filtrer les articles de news en fonction du terme de recherche
  const filteredNewsList = newsList.filter(news =>
    news.text.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <section className="section-news">
      <Navbar />
      <Toolbar onOpenModal={openAddNewsModal} onSearch={setSearchTerm} /> {/* Passer setSearchTerm à la Toolbar */}
      <div className='news-content'>
        <div className="news-grid">
          {filteredNewsList.length > 0 ? (
            filteredNewsList.map((news) => (
              <div className="news-card" key={news._id} style={{ border: `5px solid ${news.color}` }}>
                <p>{news.text}</p>
                <div className="news-actions">
                  <button className='edit-btn' onClick={() => openEditNewsModal(news)}>Éditer</button>
                  <button className="delete-btn" onClick={() => openDeleteNewsModal(news._id)}>Supprimer</button>
                </div>
              </div>
            ))
          ) : (
            <p>Aucun article trouvé.</p>
          )}
        </div>

        {/* Modals */}
        {showAddNewsModal && <AddNewsModal closeModal={closeModals} updateNewsList={updateNewsList} />}
        {showEditNewsModal && (
          <EditNewsModal news={showEditNewsModal} closeModal={closeModals} updateNews={updateNews} />
        )}
        {showDeleteNewsModal && (
          <DeleteNewsModal newsId={showDeleteNewsModal} closeModal={closeModals} removeNewsFromList={removeNewsFromList} />
        )}
      </div>
    </section>
  );
};

export default News;
