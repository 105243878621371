import React from "react";
import axios from "axios";
import './Modal.scss';

const DeletePixBotModal = ({ pixbotId, closeModal, removePixBotFromList }) => {

    const handleDelete = async () => {
        try {
            await axios.delete(`https://api.supra-event.fr/api/staffboard/pixbot/${pixbotId}`);
            removePixBotFromList(pixbotId);
            closeModal();
        } catch (error) {
            console.error("Erreur lors de la suppresion de la commandes", error.message);
        }
    };

    return (
        <div className="modal-deletepixbot">
            <div className="modal-content-deletepixbot">
                <h2>Supprimer la Commande</h2>
                <p>Êtes-vous sûr de vouloir supprimer cette commande ?</p>
                <div className="button-overlay">
                    <button className="delete-btn" onClick={handleDelete}>Supprimer</button>
                    <button className="close-btn" onClick={closeModal}>Fermer</button>
                </div>
            </div>
        </div>
    );
};

export default DeletePixBotModal;